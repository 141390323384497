@charset "utf-8";

[class*="mo-"] {
  display: none !important;
}

#wrap {
  overflow: hidden;
}

.inner {
  position: relative;
  max-width: 1200px;
  margin: auto;
  z-index: 1;
}
.inner-1340 {
  position: relative;
  max-width: 1340px;
  margin: auto;
}
.inner-1480 {
  position: relative;
  max-width: 1480px;
  margin: auto;
}

/*! main-header */
#header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  .header_content {
    display: flex;
    padding: get-vw(60px);
    .logo {
      width: 39px;
      height: 37px;
      background: url(../../images/common/logo.png) no-repeat;
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .menu {
      margin-left: auto;
      width: 32px;
      height: 29px;
      button {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        text-align: right;
        cursor: pointer;
        .hbar {
          float: right;
          display: block;
          width: 32px;
          height: 3px;
          margin-bottom: 8px;
          background-color: #463e3e;
          &:nth-child(1) {
            width: 26px;
          }
          &:nth-child(3) {
            width: 20px;
          }
        }
      }
    }
  }
}
#nav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 110;
}
.nav-wrap {
  height: 100%;
  padding: get-vw(60px) get-vw(60px) get-vw(88px) get-vw(101px);
  background-color: #ffffff;

  box-sizing: border-box;
  .close {
    text-align: right;
    margin-bottom: 10px;
    .btn-close {
      position: relative;
      width: 27px;
      height: 27px;
      padding: 0;
      margin: 0;
      cursor: pointer;
      .hbar {
        position: absolute;
        right: 12px;
        top: -4px;
        display: block;
        width: 3px;
        height: 35px;
        background-color: #000;
        transform: rotate(45deg);
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .nav-cnt-wrap {
    overflow: hidden;
    height: 100%;
  }
  .nav-list {
    float: left;
    margin-right: 54px;
    li {
      margin-bottom: 38px;
      button {
        display: block;

        &:after {
          content: "";
          clear: both;
          display: block;
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 0;
          height: 4px;
          background-color: #000;
          transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all;
        }

        .txt {
          display: inline-block;
          padding-bottom: 5px;
          transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1) all;
          font-family: $NotoSansB;
          font-size: $fontSizePc + 20;
          color: #ccc;
        }

        &.on {
          &:after {
            width: 100%;
          }

          .txt {
            color: #000;
            // border-bottom: 4px solid #000;
          }
        }

        &:hover {
          cursor: pointer;
          .txt {
            color: #000;
            // border-bottom: 4px solid #000;
          }
        }
      }
    }
  }
  .nav-cnt-box {
    overflow-y: auto;
    float: right;
    position: relative;
    height: 100%;
    padding: 0 46px;
    .nav-cnt {
      display: none;
      &.on {
        display: block;
      }
      li {
        margin-bottom: 20px;
        transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all;

        &:hover {
          box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.35);
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .brand {
      li {
        width: 254px;
        height: 146px;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all;
      }
    }
    .info {
      li {
        width: 254px;
        background-color: #f8f8f8;
        border-radius: 30px;
        transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all;

        a {
          display: block;
          width: 100%;
          padding: 20px 0;
          text-align: center;
          .txt {
            transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all;
            font-family: $NotoSansM;
            font-size: $fontSizePc + 4;
            color: #000;
          }
        }
        &:hover {
          background-color: #04a6d0;
          .txt {
            color: #fff;
          }
        }
      }
    }
    .etc {
      li {
        width: 254px;
        height: 162px;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        &.etc01 {
          background-image: url(../../images/main/etc_banner01.png);
        }
        &.etc02 {
          background-image: url(../../images/main/etc_banner02.png);
        }
        &.etc03 {
          background-image: url(../../images/main/etc_banner03.png);
        }
        &.etc04 {
          background-image: url(../../images/main/etc_banner04.png);
        }
      }
    }
  }
}

/*! sub-header */
.brand-list-wrap {
  display: none;
}
#header-sub {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
  .header-content {
    height: 90px;
    border-bottom: 1px solid #e5e5e5;
    .logo {
      position: absolute;
      top: 27px;
      left: get-vw(60px);
      z-index: 10;
      a {
        display: block;
        width: 39px;
        height: 37px;
        background: url(../../images/common/logo.png) no-repeat;
        background-size: cover;
      }
    }
    .nav {
      .swiper-wrapper {
        display: flex;
        justify-content: center;
        align-content: center;
        .swiper-slide {
          width: auto;
          align-self: center;
          a {
            display: block;
            margin: get-vw(0px) get-vw(40px);
            padding: 34px 0;
            text-align: center;
            box-sizing: border-box;
            .txt {
              // font-family: $NotoSansM;
              @include notoSansM();
              font-size: $fontSizePc + 4;
              color: #999;
            }
            &.on {
              padding: 34px 0 31px;
              border-bottom: 3px solid #000;
              .txt {
                color: #000;
              }
            }
            &:hover {
              padding: 34px 0 31px;
              border-bottom: 3px solid #000;
              .txt {
                color: #000;
              }
            }
          }
        }
      }
    }
    .util-menu {
      position: absolute;
      top: 31px;
      right: get-vw(60px);
      z-index: 10;
      .pharmacy {
        display: inline-block;
        margin-right: get-vw(80px);
        vertical-align: middle;
        .txt {
          display: inline-block;
          font-size: $fontSizePc + 2;
          vertical-align: middle;
        }
        .ico {
          display: inline-block;
          width: 18px;
          height: 24px;
          margin-right: 6px;
          vertical-align: middle;
          @include sprite-custom($pc-ico-marker);
        }
      }
      .menu {
        display: inline-block;
        width: 32px;
        height: 29px;
        vertical-align: middle;
        button {
          display: block;
          width: 100%;
          height: 100%;
          padding: 0;
          text-align: right;
          cursor: pointer;
          .hbar {
            float: right;
            display: block;
            width: 100%;
            height: 3px;
            margin-bottom: 8px;
            background-color: #463e3e;
            &:nth-child(1) {
              width: 26px;
            }
            &:nth-child(3) {
              width: 20px;
            }
          }
        }
      }
    }
  }
}

/*! sub-footer */
#footer {
  background-color: #666;
  .footer-content {
    padding: 34px 0 33px;
    .inner {
      display: flex;
      .ft-logo-wrap {
        a {
          display: inline-block;
          vertical-align: middle;
          &:nth-child(1), &:nth-child(2) {
            width: 100px;
            height: 18px;
            margin-right: 20px;
            background: url(../../images/common/logo_ft_sub1.png) no-repeat;
            background-size: cover;

            &:lang(en),&:lang(ja),&:lang(zh) {
              width: 188px;
              background: url(../../images/common/logo_ft_sub1_en.png) no-repeat;
            }
          }
          &:last-child {
            width: 61px;
            height: 16px;
            background: url(../../images/common/logo_ft_sub2.png) no-repeat;
            background-size: contain;
          }
        }
      }
      p {
        font-family: $NotoSansL;
        font-size: 14px;
        color: #fff;
        margin-left: auto;
      }
    }
  }
}

/*! popup */

/*! layout */
#wrap {
  position: relative;
}
.sub {
  #container {
    padding-top: 90px;
  }
}

// 태블릿
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .inner {
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
  }
}
