@charset "utf-8";

[class*="web-"] {
  display: none;
}
#wrap {
  position: relative;
}

.inner {
  position: relative;
  width: 100%;
  padding: 0 get-vw-mo(40px);
  box-sizing: border-box;
}

/*! header */
#header {
  .header_content {
    display: flex;
    padding: get-vw-mo(30px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #fff;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
    .logo {
      width: get-vw-mo(52px);
      height: get-vw-mo(51px);
      background: url(../../images/mo/logo.png) no-repeat;
      background-size: cover;
    }
    .txt {
      position: absolute;
      left: 0;right: 0;
      display: block;
      width: 100%;
      text-align: center;
      align-self: center;
      font-family: $NotoSansB;
      font-size: get-vw-mo(36px);
    }
    .menu {
      margin-left: auto;
      width: get-vw-mo(44px);
      height: get-vw-mo(29px);
      button {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        text-align: right;
        cursor: pointer;
        background: url(../../images/common/ico_menu.png) no-repeat;
        background-size: cover;
        .hbar {
          display: none;
        }
      }
    }
  }
}

#nav {
  display: none;
  position: fixed;
  // top: get-vw-mo(-203px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 100;
  overflow-y: auto;
}

.nav-wrap {
  padding: get-vw-mo(40px) get-vw-mo(40px) get-vw-mo(100px) get-vw-mo(40px);
  background-color: #ffffff;
  z-index: 20;
  box-sizing: border-box;
  .close {
    text-align: right;
    margin-bottom: get-vw-mo(60px);
    .btn-close {
      position: relative;
      width: get-vw-mo(35px);
      height: get-vw-mo(35px);
      padding: 0;
      margin: 0;
      .hbar {
        position: absolute;
        right: get-vw-mo(15px);
        top: get-vw-mo(-6px);
        display: block;
        width: get-vw-mo(4px);
        height: get-vw-mo(46px);
        background-color: #000;
        transform: rotate(45deg);
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .nav-cnt-box {
    padding: get-vw-mo(40px) get-vw-mo(0px);
    border-bottom: 1px solid #eee;
    .nav-tit {
      button {
        position: relative;
        display: block;
        width: 100%;
        text-align: left;

        &.on {
          .txt:after {
            width: 100%;
          }
        }
        .txt {
          display: inline-block;
          @include notoSansB();
          font-size: get-vw-mo(42px);

          &:after {
            content: "";
            clear: both;
            display: block;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 0;
            height: 4px;
            background-color: #000;
            transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all;
          }
        }
        em {
          position: absolute;
          right: get-vw-mo(6px);
          top: get-vw-mo(18px);
          display: inline-block;
          width: get-vw-mo(3px);
          height: get-vw-mo(20px);
          background-color: #999;
          transform: rotate(-45deg);
          &.ico01 {
            right: get-vw-mo(18px);
          }
          &.ico02 {
            transform: rotate(45deg);
          }
        }
        &.on {
          em {
            transform: rotate(45deg);
            &.ico02 {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    .nav-cnt {
      display: none;
      margin-top: get-vw-mo(40px);
      &.brand {
        ul {
          li {
            float: left;
            width: get-vw-mo(310px);
            height: get-vw-mo(184px);
            margin-right: get-vw-mo(30px);
            margin-bottom: get-vw-mo(30px);
            border-radius: get-vw-mo(16px);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            a {
              display: block;
              width: 100%;
              height: 100%;
            }
            &:nth-child(even) {
              margin-right: 0;
            }
            // &.banner01 {
            //   background-image: url(../../images/mo/small_banner01.png);
            // }
            // &.banner02 {
            //   background-image: url(../../images/mo/small_banner02.png);
            // }
            // &.banner03 {
            //   background-image: url(../../images/mo/small_banner03.png);
            // }
            // &.banner04 {
            //   background-image: url(../../images/mo/small_banner04.png);
            // }
            // &.banner05 {
            //   background-image: url(../../images/mo/small_banner05.png);
            // }
            // &.banner06 {
            //   background-image: url(../../images/mo/small_banner06.png);
            // }
            // &.banner07 {
            //   background-image: url(../../images/mo/small_banner07.png);
            // }
            // &.banner08 {
            //   background-image: url(../../images/mo/small_banner08.png);
            // }
            // &.banner09 {
            //   background-image: url(../../images/mo/small_banner09.png);
            // }
            // &.banner10 {
            //   background-image: url(../../images/mo/small_banner10.png);
            // }
          }
        }
      }
      &.info {
        a {
          display: inline-block;
          padding: get-vw-mo(0px) get-vw-mo(20px);
          .txt {
            display: inline-block;
            @include font($NotoSansM, get-vw-mo(30px), 2.6, #666);
          }
        }
      }
      &.etc {
        // a {
        //   display: inline-block;
        //   padding: get-vw-mo(0px) get-vw-mo(20px);
        //   .txt {
        //     display: inline-block;
        //     @include font($NotoSansM, get-vw-mo(30px), 2.6, #666);
        //     vertical-align: middle;
        //   }
        //   .ico {
        //     display: inline-block;
        //     width: get-vw-mo(27px);
        //     height: get-vw-mo(26px);
        //     margin-left: get-vw-mo(14px);
        //     background: url(../../images/mo/ico_link.png) no-repeat;
        //     background-size: cover;
        //     vertical-align: middle;
        //   }
        // }
        li {
          float: left;
          width: get-vw-mo(310px);
          height: get-vw-mo(199px);
          margin-right: get-vw-mo(30px);
          margin-bottom: get-vw-mo(30px);
          background-repeat: no-repeat;
          background-size: contain;
          &.etc01 {
            background-image: url(../../images/main/etc_banner01.png);
          }
          &.etc02 {
            background-image: url(../../images/main/etc_banner02.png);
          }
          &.etc03 {
            background-image: url(../../images/main/etc_banner03.png);
          }
          &.etc04 {
            background-image: url(../../images/main/etc_banner04.png);
          }
          &:nth-child(even) {
            margin-right: 0;
          }
          a {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      &.on {
        display: block;
      }
    }
  }
}

#container {
  margin-top: get-vw-mo(184px);
  min-height: 76vh;
}

/*! sub-header */
#header-sub {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
  .header-content {
    border-bottom: 1px solid #e5e5e5;
    .header-wrap {
      display: flex;
      justify-content: center;

      position: relative;
      z-index: 20;
      background: #fff;

      .logo {
        position: absolute;
        top: get-vw-mo(30px);
        left: get-vw-mo(30px);
        z-index: 1;
        a {
          display: block;
          width: get-vw-mo(52px);
          height: get-vw-mo(51px);
          background: url(../../images/mo/logo.png) no-repeat;
          background-size: contain;
        }
      }
      .mo-brand-menu {
        width: 100%;
        padding: get-vw-mo(27px) 0 get-vw-mo(12px);
        align-self: center;
        text-align: center;
        .current {
          display: inline-block;
          outline: none;
          .txt {
            display: inline-block;
            @include notoSansB();
            font-size: get-vw-mo(36px);
            vertical-align: middle;
          }
          .ico {
            display: inline-block;
            margin-left: get-vw-mo(7px);
            width: get-vw-mo(34px);
            height: get-vw-mo(34px);
            vertical-align: middle;
            background: url(../../images/mo/ico_menu_arrow.png) no-repeat;
            background-size: contain;
          }
          &.on {
            .ico {
              transform: rotate(180deg);
            }
          }
        }
      }
      .util-menu {
        position: absolute;
        top: get-vw-mo(30px);
        right: get-vw-mo(30px);
        width: get-vw-mo(44px);
        height: get-vw-mo(29px);
        .menu {
          display: block;
          width: 100%;
          height: 100%;
          button {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0;
            text-align: right;
            cursor: pointer;
            background: url(../../images/common/ico_menu.png) no-repeat;
            background-size: cover;
            .hbar {
              display: none;
            }
            &:after {
              content: "";
              display: block;
              clear: both;
            }
          }
        }
      }
    }
    .brand-list-wrap {
      display: none;
      overflow: auto;
      position: fixed;
      top: get-vw-mo(114px);
      left: 0;
      right: 0;
      height: calc(100% - 130px);
      z-index: 11;
      .brand-list {
        background-color: #fff;
        li {
          padding: 0 get-vw-mo(60px);
          a {
            display: block;
            width: 100%;
            height: 100%;
            padding: get-vw-mo(40px) 0;
            text-align: center;
            border-bottom: 1px solid #eee;
            .txt {
              font-size: get-vw-mo(32px);
              @include notoSansM();
            }
          }
          &:hover, &.on {
            background-color: #f1f5f8;
          }
          &:last-child {
            a {
              border-bottom: 0;
            }
          }
        }
      }
    }
    .nav {
      width: 100%;
      .swiper-wrapper {
        width: auto;
        min-width: 100%;
        display: flex;
        position: relative;
        background-color: #fff;
        .swiper-slide {
          flex-shrink: 0;
          width: 33.333333%;
          white-space: nowrap;
          a {
            display: block;
            padding: get-vw-mo(30px) get-vw-mo(0px) get-vw-mo(31px);
            text-align: center;
            .txt {
              @include notoSansM();
              font-size: get-vw-mo(28px);
              color: #999;
            }
            &.on {
              padding: get-vw-mo(30px) get-vw-mo(0px) get-vw-mo(26px);
              border-bottom: get-vw-mo(5px) solid #000;
              .txt {
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}
.services, .pharmacy {
  #header-sub {
    .header-content {
      .header-wrap {
        .mo-brand-menu {
          padding: get-vw-mo(27px) 0;
        }
      }
    }
  }
}

/*! sub-footer */
#footer {
  background-color: #666;
  .footer-content {
    padding: get-vw-mo(50px) 0 get-vw-mo(40px);
    .ft-logo-wrap {
      a {
        display: inline-block;
        vertical-align: middle;
        &:nth-child(1), &:nth-child(2) {
          width: get-vw-mo(201px);
          height: get-vw-mo(37px);
          margin-right: get-vw-mo(50px);
          background: url(../../images/mo/logo_ft_sub1.png) no-repeat;
          background-size: contain;

          &:lang(en),&:lang(ja),&:lang(zh) {
            width: get-vw-mo(389px);
            background: url(../../images/common/logo_ft_sub1_en.png) no-repeat;
            background-size: contain;
          }
        }
        &:last-child {
          width: get-vw-mo(133px);
          height: get-vw-mo(34px);
          background: url(../../images/mo/logo_ft_sub2.png) no-repeat;
          background-size: contain;
        }
        &.web-link {
          display: none;
        }
      }
    }
    p {
      @include notoSansL();
      font-size: get-vw-mo(24px);
      color: #fff;
      margin-top: get-vw-mo(35px);
    }
  }
}

// 태블릿 (mo 기반)
@media screen and (min-width: 750px) and (max-width: 1023px) {
  #container {
    margin-top: 184px;
  }

  #header {
    .header_content {
      padding: 30px;
      .logo {
        width: 52px;
        height: 51px;
      }
      .txt {
        font-size: 36px;
      }
      .menu {
        width: 44px;
        height: 29px;
      }
    }
  }

  .nav-wrap {
    padding: 40px 40px 100px 40px;
    .close {
      margin-bottom: 60px;
      .btn-close {
        width: 35px;
        height: 35px;
        .hbar {
          right: 15px;
          top: -6px;
          width: 4px;
          height: 46px;
        }
      }
    }
    .nav-cnt-box {
      padding: 40px 0px;
      .nav-tit {
        a {
          .txt {
            font-size: 42px;
            border-bottom: 5px solid #222;
          }
          em {
            right: 6px;
            top: 18px;
            width: 3px;
            height: 20px;
            &.ico01 {
              right: 18px;
            }
          }
        }
      }
      .nav-cnt {
        margin-top: 40px;
        &.info {
          a {
            padding: 0 20px;
            .txt {
              font-size: 30px;
            }
          }
        }
        &.etc {
          a {
            padding: 0 20px;
            .txt {
              font-size: 30px;
            }
            .ico {
              width: 27px;
              height: 26px;
              margin-left: 14px;
            }
          }
        }
      }
    }
  }

  #header-sub {
    .header-content {
      .header-wrap {
        .logo {
          top: 30px;
          left: 30px;
          a {
            width: 52px;
            height: 51px;
          }
        }
        .mo-brand-menu {
          padding: 27px 0 12px;
          .current {
            .txt {
              font-size: 36px;
            }
            .ico {
              margin-left: 7px;
              width: 34px;
              height: 34px;
            }
          }
        }
        .util-menu {
          position: absolute;
          top: 30px;
          right: 30px;
          .menu {
            width: 44px;
            height: 29px;
          }
        }
      }

      .brand-list-wrap {
        top: 114px;
        .brand-list {
          li {
            padding: 0 60px;
            a {
              padding:40px 0;
              .txt {
                font-size: 32px;
              }
            }
          }
        }
      }
      .nav {
        ul {
          li {
            a {
              padding: 30px 0px 31px;
              .txt {
                font-size: 28px;
              }
              &.on {
                padding: 30px 0px 26px;
                border-bottom: 5px solid #000;
              }
            }
          }
        }
        .swiper-wrapper {
          .swiper-slide {
            a {
              padding: 30px 0px 31px;
              .txt {
                font-size: 28px;
              }
              &.on {
                padding: 30px 0px 26px;
                border-bottom: 5px solid #000;
              }
            }
          }
        }
      }
    }
  }

  .services, .pharmacy  {
    #header-sub {
      .header-content {
        .header-wrap {
          .mo-brand-menu {
            padding: 27px 0;
          }
        }
      }
    }
  }

  #footer {
    .footer-content {
      .ft-logo-wrap {
        a {
          &:nth-child(1), &:nth-child(2) {
            width: 201px;
            height: 37px;
            margin-right: 50px;

            &:lang(en),
            &:lang(ja),
            &:lang(zh) {
              width:389px;
            }
          }
          &:nth-child(2) {
            width: 190px;
            height: 34px;
            background-size: cover;
          }
          &:last-child {
            width:133px;
            height: 34px;
          }
        }
      }


      p {
        font-size: 24px;
        margin-top: 35px;
      }
    }
  }
}

//익스
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #container {
    margin-top: get-vw-mo(172px);
  }

  .brand {
    .title-wrap {
      .title {
        .sub-txt {
          span {
            letter-spacing: -1px !important;
          }
        }
      }
      .sub-txt {
        span {
          letter-spacing: -1px !important;
        }
      }
    }
  }
  .panpyrin .section01 .sub-tit {
    display: block;
  }
  @media screen and (min-width: 750px) and (max-width: 1023px) {
    #container {
      margin-top: 172px;
    }
  }
}