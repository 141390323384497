@charset "utf-8";
// 스프라이트 이미지 include 사용 예제
// PC 용 - @include sprite-custom($pc-ico-paginav-first);
// Mobile 용 - @include retina-sprite-custom($mobile-ico-paginav-first-group);

// 아래 소스는 PC 기준.
// 모바일 소스는 아래 소스를 변경해서 사용.
// 반응형 소스는 PC를 복사해서 소스 2벌 만들기. (미디어쿼리 사용)
// PC, Mobile 소스간 간섭을 막기 위함.

/*! btn */
.bttn{
	display:inline-block;vertical-align: middle;
	position:relative;
	cursor: pointer;
}

// 버튼 타입 설정 (모양등)
.bttn.type01{
	padding:10px 20px 10px 20px;
	line-height: 30px;
	text-align: center;
	border-radius: 20px;
}
.bttn.type02{
	padding:10px 20px 10px 20px;
	line-height: 30px;
	text-align: center;
}
.bttn.type03{
	min-width:280px;
	padding:10px 10px 10px 10px;
	line-height: 30px;
	text-align: left;
}
.bttn.type04{
	min-width:280px;
	padding:10px 10px 10px 10px;
	line-height: 30px;
	text-align: right;
}

// 버튼 스타일 설정 (배경컬러, 보더등)
.bttn.style01{
	background:orange;
	border:1px solid orange;
}
.bttn.style02{
	background: lightcoral;
	border:1px solid lightcoral;
}

// 버튼 자식요소 - 텍스트
.bttn .txt01{
	display:inline-block;vertical-align: middle;
	font-size:16px;color:#000000;
	line-height: 1.2;
}

// 버튼 자식요소 - 아이콘
.bttn .ico01{
	display:inline-block;vertical-align: middle;
	margin-left:10px;
	@include sprite-custom($pc-ico-paginav-last);
}
.bttn .ico02{
	display:inline-block;vertical-align: middle;
	margin-right:10px;
	@include sprite-custom($pc-ico-paginav-first);
}
.bttn .ico03{
	display:block;position:absolute;top:50%;right:10px;
	margin-top:-8px;
	@include sprite-custom($pc-ico-paginav-last);
}
.bttn .ico04{
	display:block;position:absolute;top:50%;left:10px;
	margin-top:-8px;
	@include sprite-custom($pc-ico-paginav-first);
}




















/*! form */
// form - checkbox
.input-checkbox{
	display:inline-block;
	height:20px;
	input{
		border: 0;
		clip: rect(0 0 0 0);
		width: 1px;height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
	}
	.txt{
		display:inline-block;
		line-height: 1;
		color:#000000;font-size:14px;
	}
	label{
		display:inline-block;
		padding:0 10px 0 10px;
		position: relative;
		user-select: none;
	}
	label:before{
		content:'';
		box-sizing: border-box;
		position: absolute;
		top: 50%;left: 0;
		width: 20px;height: 20px;
		margin-top: -10px;
		border: 2px solid grey;
		text-align: center;
	}
	label:after{
		// 애니메이션 제거시 아래 display 주석 해제 하기
		// display:none;
		content:'';
		position: absolute;
		top: 50%;left: 5px;
		width: 8px;height: 3px;
		margin-top: -4px;
		border-top:0;border-right:0;
		border-bottom:3px solid #900900;
		border-left:3px solid #900900;
		transform: rotate(-45deg);
	}
	&.right{
		label{padding:0 0 0 24px;}
		label:before{margin-top:-12px;}
		label:after{margin-top:-6px;}
	}
	&.left{
		label{padding:0 24px 0 0;}
		label:before{left:auto;right:0;margin-top:-12px;}
		label:after{left:auto;right:4px;margin-top:-6px;}
	}
	&.up{
		label{padding:0 0 20px 0;}
		label:before{top:auto;bottom:0;left:50%;margin-left:-10px;margin-top:0;}
		label:after{top:auto;bottom:8px;left:50%;margin-left:-5px;margin-top:0;}
	}
	&.down{
		label{padding:20px 0 0 0;}
		label:before{top:0;left:50%;margin-left:-10px;margin-top:0;}
		label:after{top:6px;left:50%;margin-left:-5px;margin-top:0;}
	}
	input:focus + label:before{border-color:black;}
	input:hover + label:before{border-color:black;}
	input:disabled:focus + label:before{border-color:grey;}
	input:disabled:hover + label:before{border-color:grey;}
	// 애니메이션 제거시 아래 display 주석 해제 하기
	// input:checked + label:after{display:block;}
	input:disabled + label:before{opacity: 0.5;}
	input:disabled + label:after{opacity: 0.5;}

	// 애니메이션 영역 - 필요 없을시 아래 소스 삭제하기
	label:after{
		transform: rotate(-45deg) scale(0);
		transition:none;
	}
	input:checked + label:after {
		transform: rotate(-45deg) scale(1);
		transition: transform 200ms ease-out;
	}
}

// form - radio
.input-radio{
	display:inline-block;
	height:20px;
	input{
		border: 0;
		clip: rect(0 0 0 0);
		width: 1px;height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
	}
	.txt{
		display:inline-block;
		line-height: 1;
		color:#000000;font-size:14px;
	}
	label{
		display:inline-block;
		position: relative;
		padding: 0 10px 0 10px;
		user-select: none;
	}
	label:before{
		content:'';
		box-sizing: border-box;
		position: absolute;
		top: 50%;left: 0;
		width: 20px;height: 20px;
		margin-top: -10px;
		border: 2px solid grey;
		text-align: center;
		border-radius: 50%;
	}
	label:after{
		// 애니메이션 제거시 아래 display 주석 해제 하기
		// display:none;
		content:'';
		background-color: #900;
		position: absolute;
		top: 50%;left: 5px;
		width: 10px;height: 10px;
		margin-top: -5px;
		border-radius: 50%;
	}
	&.right{
		label{padding:0 0 0 24px;}
		label:before{margin-top:-12px;}
		label:after{margin-top:-7px;}
	}
	&.left{
		label{padding:0 24px 0 0;}
		label:before{left:auto;right:0;margin-top:-12px;}
		label:after{left:auto;right:5px;margin-top:-7px;}
	}
	&.up{
		label{padding:0 0 20px 0;}
		label:before{top:auto;bottom:0;left:50%;margin-left:-10px;margin-top:0;}
		label:after{top:auto;bottom:5px;left:50%;margin-left:-5px;margin-top:0;}
	}
	&.down{
		label{padding:20px 0 0 0;}
		label:before{top:0;left:50%;margin-left:-10px;margin-top:0;}
		label:after{top:5px;left:50%;margin-left:-5px;margin-top:0;}
	}
	input:focus + label:before{border-color:black;}
	input:hover + label:before{border-color:black;}
	input:disabled:focus + label:before{border-color:grey;}
	input:disabled:hover + label:before{border-color:grey;}
	// 애니메이션 제거시 아래 display 주석 해제 하기
	// input:checked + label:after{display:block;}
	input:disabled + label:before{opacity: 0.5;}
	input:disabled + label:after{opacity: 0.5;}

	// 애니메이션 영역 - 필요 없을시 아래 소스 삭제하기
	label:after{
		transform: rotate(-45deg) scale(0);
		transition:none;
	}
	input:checked + label:after {
		transform: rotate(-45deg) scale(1);
		transition: transform 200ms ease-out;
	}
}

// form - input text
.input-txt{
	display:inline-block;position:relative;width:100%;
	border:1px solid #cccccc;
	box-sizing: border-box;background-color: #ffffff;
	input{
		display:inline-block;padding:0 0 0 15px; width:100%;font-size:12px;
		height:38px;line-height:38px;border:none;box-sizing: border-box;
		appearance: none;border-radius: 0;
	}
}
.input-txt input:read-only{opacity:0.5;}

// form - placeholder
// Chrome, Firefox, Opera, Safari 10.1+
.input-txt input::placeholder{
	font-size:12px;color:#999999;
	background:transparent;
	// 아래 속성은 Firefox 용
	opacity: 1;
}
.input-txt input:-ms-input-placeholder{position:relative;top:1px;}
.input-txt input::-ms-input-placeholder{position:relative;top:1px;}

// form - selectbox
.selectbox{
	position:relative;z-index:0;display:inline-block;width:100%;
	overflow:hidden;border:1px solid #cccccc;
	box-sizing: border-box;
	&:after{
		content:'';display:block;position:absolute;
		top:50%;right:0;margin-top:-3px;
		@include sprite-custom($pc-ico-selectbox-down);
	}
	select{
		vertical-align:middle;
		border:none;appearance:none;border-radius:0;
		width:100%;
		padding:0 0 0 15px;
		box-sizing: border-box;
		height:38px;
		line-height:38px;
		background-color:transparent;
		position: relative;z-index: 1;
	}
	select::-ms-expand{display:none;}
	select:disabled{opacity:0.5;}
}
.ie9 select{
	width:calc(100% + 20px);
	padding:12px 0 10px 15px;
	height:auto;
}
// ie10 ~ ie11 에만 적용되는 css 를 만들때 사용하는 미디어쿼리 소스
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	// IE10+ CSS here
	.selectbox{
		// 텍스트 수직 중앙정렬을 위해서 ie10 ~ 11 속성 재정의
		select{
			padding:12px 0 10px 15px;
			height:auto;
		}
	}
}





















/*! page navigation */
// page navigation
.paginav{text-align:center;}
.paging-util{
	position: relative;
	display:inline-block;
	width:40px;height:40px;line-height: 40px;
	vertical-align: top;
	.icon{position:absolute;top:50%;left:50%;}
}
.btn-paging-first .icon{
	@include sprite-custom($pc-ico-pagi-first);
	margin:-7px 0 0 -7px;
}
.btn-paging-prev .icon{
	@include sprite-custom($pc-ico-pagi-prev);
	margin:-7px 0 0 -4px;
}
.btn-paging-next .icon{
	@include sprite-custom($pc-ico-pagi-next);
	margin:-7px 0 0 -4px;
}
.btn-paging-last .icon{
	@include sprite-custom($pc-ico-pagi-last);
	margin:-7px 0 0 -7px;
}
.paging-list{display:inline-block;vertical-align: top;}
.paging-num{
	display:inline-block;
	width:36px;height:36px;
	// line-height:36px;
	vertical-align: top;
	border-radius: 100%;
	box-sizing: border-box;
	.txt{
		@include font($NotoSansDL, $fontSizePc + 2, 36px, #999);
	}
	&.on{
		border:2px solid #222;
	}
	&.on .txt{
		@include font($NotoSansM, $fontSizePc + 2, 32px, #222);
	}

}


// page navigation mobile
// type1
.type-mo{
	.paging-util {
		width: get-vw-mo(66px);height: get-vw-mo(66px);
		line-height: get-vw-mo(66px);
	}
	.paging-num{
		width: get-vw-mo(66px);height: get-vw-mo(66px);
		box-sizing: border-box;
		@include font($NotoSansDL, get-vw-mo(32px), normal, #999);
		.txt{@include font($NotoSansDL, get-vw-mo(32px), get-vw-mo(66px), #999);}
		&.on{border:get-vw-mo(3px) solid #222;}
		&.on .txt{@include font($NotoSansM, get-vw-mo(32px), get-vw-mo(62px), #222);}

	}
}

/*! map api */
.map-api{
	width: 300px; height: 300px; background:#000;
}

/*! movie */
.movie-area{
	width: 300px; height: 300px;
	iframe{
		border: none;
	}
	video{
		width: 100%; height: 100%;
	}
}

/*! tab menu */

//tab-menu switch Type
.tab-menu-type01 {
	position: relative;
	width: 30px;height: 58px;
	padding: 4px;
	border-radius: 15px;
	background-color: #fff;
	box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
	box-sizing: border-box;
	cursor: pointer;
	.list {
		display: block;
		width: 11px;height: 11px;
		margin: 0 auto;
		@include sprite-custom($pc_btn_list);
	}
	.card {
		display: block;
		width: 10px;height: 10px;
		margin: 15px auto 0;
		@include sprite-custom($pc_btn_card);
	}
	.round {
		position: absolute;
		top: 4px;left: 4px;
		width: 22px;height: 22px;
		background-color: #000;
		border-radius: 100%;
		&.card-type {
			top: inherit;
			bottom: 4px;
		}
	}
	.transition {
		transition: 0.65s cubic-bezier(.66,0,.34,1) all;
	}
}
.tab-menu-type02 {
	margin-bottom: 50px;
	button {
		width: 216px;
		padding-bottom: 19px;
		border-bottom: 1px solid #ddd;
		.txt {
			font-family: $NotoSansM;
			font-size: $fontSizePc + 6;
			color: #999;
		}
		& + button {
			margin-left: -4px;
		}
		&.on {
			padding-bottom: 15px;
			border-bottom: 4px solid #000;
			.txt {
				color: #222;
			}
		}
	}
}









/*! table */
// col 타입 1
.table-wrap-type01{
	width:100%;max-width:1280px;
	margin:0 auto;padding-top:12px;
}
.table-col-type01{
	width:100%;
	background:#edeef3;

	th{
		color:#333130;
		text-align:center;
		border-left:1px solid #ffffff;
		height:42px;
		&:first-child{border-left:0}
	}
	th .txt{
		position: relative;
		top:-20px;
	}
	td{
		color:#4d4948;
		text-align:center;
		line-height:2.22222222;
		border-left:1px solid #ffffff;
		vertical-align: top;
		&:first-child{border-left:0}
	}
	td.left{
		text-align:left;
		padding-left:40px;
	}
	td.middle{
		vertical-align: middle;
	}
	tr:last-child td{
		padding-bottom:27px;
	}
	.btn-download-dark{
		display:inline-block;width:40px;height:40px;
		// background:red;
		vertical-align: middle;position: relative;
		.ico{
			position:absolute;top:50%;left:50%;margin:-9px 0 0 -9px;
			@include sprite-custom($pc-ico-download-dark);
		}
	}
	.btn-link01{
		display:inline-block;vertical-align: middle;
		.txt{
			color:#4d4948;
		}
		.ico{
			display:inline-block;vertical-align: middle;
			@include sprite-custom($pc-ico-download-dark);
		}
	}
}

// col 타입 2
.table-wrap-type02{
	width:100%;max-width:1280px;
	margin:0 auto;
}
.table-col-type02{
	width:100%;table-layout:fixed;
	thead{
		border:1px solid #999290;
	}
	th{
		color:#333130;
		text-align:center;
		height:57px;
	}
	td{
		color:#4d4948;
		text-align:center;
		line-height:3.1;
		vertical-align: middle;
		height:56px;
		border-bottom:1px solid #cccccc;
		.txt-link{
			width:calc(100% - 10px);
			display:block;
			color:#4d4948;
		}
		@include txt-line-clamp(3.1, 1);
	}
	td.left{
		text-align:left;
	}
	tr:first-child td{
		padding-top:10px;
		height:58px;
	}
	.btn-download-dark{
		display:inline-block;width:40px;height:40px;
		// background:red;
		vertical-align: middle;position: relative;
		.ico{
			position:absolute;top:50%;left:50%;margin:-9px 0 0 -9px;
			@include sprite-custom($pc-ico-download-dark);
		}
	}
}
// 아래 colgroup 은 프로토타입용 샘플.
// 실제 colgroup 은 카테고리 css 에서 관리하기
.colgroup-prototype01-01{
	.col01{width:160px;}
	.col02{width:auto;}
	.col03{width:240px;}
}
// 아래 colgroup 은 프로토타입용 샘플.
// 실제 colgroup 은 카테고리 css 에서 관리하기
.colgroup-prototype01-02{
	.col01{width:160px;}
	.col02{width:auto;}
	.col03{width:240px;}
}
// 아래 colgroup 은 프로토타입용 샘플.
// 실제 colgroup 은 카테고리 css 에서 관리하기
.colgroup-prototype01-03{
	.col01{width:160px;}
	.col02{width:160px;}
	.col03{width:auto;}
	.col04{width:160px;}
	.col05{width:240px;}
}
// 아래 colgroup 은 프로토타입용 샘플.
// 실제 colgroup 은 카테고리 css 에서 관리하기
.colgroup-prototype01-04{
	.col01{width:auto;}
	.col02{width:auto;}
	.col03{width:auto;}
	.col04{width:auto;}
	.col05{width:auto;}
}
// 아래 colgroup 은 프로토타입용 샘플.
// 실제 colgroup 은 카테고리 css 에서 관리하기
.colgroup-prototype02-01{
	.col01{width:110px;}
	.col02{width:auto;}
	.col03{width:140px;}
}
// 아래 colgroup 은 프로토타입용 샘플.
// 실제 colgroup 은 카테고리 css 에서 관리하기
.colgroup-prototype02-02{
	.col01{width:110px;}
	.col02{width:auto;}
	.col03{width:140px;}
}




/*! prototype_mixin */


//locationWrap _ pc
.location-wrp {
	a {
		display: inline-block;
    padding-right: 20px;
    position: relative;
    color: #767676;
		font-size: 15px;

		&:before {
			position: absolute;
			display: inline-block;
			content: '';
			@include sprite-custom($pc_ico_location_div);
			top: 4px;
			right: 4px;
		}

		&:last-child {
			color: #222;
			font-weight:bold;


			&:before {
				display:none;
			}
		}
	}
}


//locationWrap _ pc
.location-wrp2 {
	> ul{
		> li{
			float: left;
			position: relative;
			ul{
				display: none;
				position: absolute; left: 0; top: 20px;
			}
			&:last-child a{
				color: #222;
				font-weight:bold;
				&:before{display: none;}
			}
			> a {
				display: inline-block;
				padding-right: 20px;
				position: relative;
				color: #767676;
				font-size: 15px;

				&:before {
					position: absolute;
					display: inline-block;
					content: '';
					@include sprite-custom($pc_ico_location_div);
					top: 4px;
					right: 4px;
				}

				&:last-child {
					color: #222;
					font-weight:bold;


					&:before {
						display:none;
					}
				}
			}
		}
	}
}


/*! accordion */
.ui-accordion {
	width: 100%;
	border-top: 2px solid #222;
	padding-bottom: 40px;
	.accordion-item {
		border-bottom: 1px solid #ddd;
		.btn {
			position: relative;
			width: 100%;
			padding: 28px 29px;
			text-align: left;
			cursor: pointer;
			.ico-q {
				display: inline-block;
				vertical-align: middle;
				@include fontEng($fontEng, 600, $fontSizePc + 8, 1.4, #00a551)
			}
			.txt {
				display: inline-block;
				word-break: keep-all;
				vertical-align: middle;
				@include font($NotoSansM, $fontSizePc + 6, 1.5, #333)
			}
			.ico01, .ico02 {
				position:absolute;
				top:43px;right:30px;
				width:20px;
				height:2px;
				background:#222;
				transition:.4s all;
			}
			.ico01 {
				transform:rotateZ(90deg)
			}
		}
		.detail {
			display: none;
			padding: 35px 29px;
			text-align: left;
			background-color: #fff;
			h2 {
				font-size: 1.5em;
			}
			h3 {
				font-size: 1.17em;
			}
			h4 {
				font-size: 13px;
			}

			.text-tiny {
				font-size: .7em;
			}
			.text-small {
				font-size: .85em;
			}
			.text-big {
				font-size: 1.4em;
			}
			.text-huge {
				font-size: 1.8em;
			}
			strong {
				font-weight: 700;
			}
			blockquote {
				overflow: hidden;
				padding-right: 1.5em;
				padding-left: 1.5em;
				margin-left: 0;
				margin-right: 0;
				font-style: italic;
				border-left: 5px solid #ccc;
			}
			ol {
				list-style-type: decimal;
				padding-left: 21px;
				@include font($NotoSansDL, $fontSizePc, 1.75, #666)
			}
			ul {
				list-style-type: circle;
				padding-left: 21px;
				@include font($NotoSansDL, $fontSizePc, 1.75, #666)
			}
			p {
				word-break: keep-all;
				white-space: pre-line;
				@include font($NotoSansDL, $fontSizePc, 1.75, #666)
			}
		}
		&.on {
			.ico01 {
				transform:rotateZ(0)
			}
			.detail {
				// display: block;
			}
		}
	}
}































/*! table */



















/*! mobile*/

/*! tab */
@media screen and (max-width:1023px){

	/*! tab menu */
	.main-tab-wrap {text-align: center;}
	//tab-menu switch Type
	.tab-menu-type01 {
		position: relative;
		width: get-vw-mo(118px);
		height: get-vw-mo(62px);
		padding: get-vw-mo(7px);
		margin: get-vw-mo(30px) auto get-vw-mo(0px);
		border-radius: get-vw-mo(31px);
		background-color: #fff;
		box-sizing: border-box;
		box-shadow: none;
		border: 1px solid #e5e5e5;
		.btn-wrap {
			display: flex;
		}
		.list {
			// display: inline-block;
			// margin: get-vw-mo(5px) get-vw-mo(0px) get-vw-mo(0px) get-vw-mo(10px);
			width: get-vw-mo(18px);height: get-vw-mo(18px);
			background: url(../../images/mo/btn_card.png);
			background-size: contain;
			margin: 0;
			position: absolute;			
			left: get-vw-mo(20px);
			top: 50%;
			transform: translateY(-50%);
		}
		.card {
			// display: inline-block;
			// margin: get-vw-mo(0px) get-vw-mo(0px) get-vw-mo(0px) get-vw-mo(29px);
			width: get-vw-mo(22px);height: get-vw-mo(17px);
			background: url(../../images/mo/btn_list.png);
			background-size: contain;
			margin: 0;
			position: absolute;			
			right: get-vw-mo(18px);
			top: 50%;
			transform: translateY(-50%);
		}
		.round {
			position: absolute;
			right: inherit;
			left: get-vw-mo(7px);
			top: get-vw-mo(7px);
			width: get-vw-mo(44px);height: get-vw-mo(44px);
			background-color: #000;
			border-radius: 100%;
			&.card-type {
				top: get-vw-mo(7px);right: get-vw-mo(7px);left: inherit;
			}
		}
	}

	.tab-menu-type02 {
		margin-bottom: get-vw-mo(70px);
		button {
			width: 50%;
			padding-bottom: get-vw-mo(30px);
			border-bottom: 1px solid #ddd;
			.txt {
				font-family: $NotoSansM;
				font-size: get-vw-mo(32px);
				color: #999;
			}
			& + button {
				margin-left: -4px;
			}
			&.on {
				padding-bottom: get-vw-mo(25px);
				border-bottom: get-vw-mo(5px) solid #000;
				.txt {
					color: #222;
				}
			}
		}
	}

	/*! accordion */
	.ui-accordion {
		width: 100%;
		border-top: 2px solid #222;
		.accordion-item {
			border-bottom: 1px solid #ddd;
			.btn {
				position: relative;
				width: 100%;
				padding: get-vw-mo(37px) get-vw-mo(20px);
				text-align: left;
				cursor: pointer;
				.ico-q {
					display: inline-block;
					margin-right: get-vw-mo(20px);
					vertical-align: middle;
					@include fontEng($fontEng, 600, get-vw-mo(36px), 1, #00a551)
				}
				.txt {
					display: inline-block;
					width: 75%;
					word-break: keep-all;
					vertical-align: middle;
					@include font($NotoSansM, get-vw-mo(32px), 1.4, #333)
				}
				.ico01, .ico02 {
					position:absolute;
					top:50%;right:get-vw-mo(20px);
					width:get-vw-mo(31px);
					height:get-vw-mo(3px);
					background:#222;
					transition:.4s all;
				}
				.ico01 {
					transform:rotateZ(90deg)
				}
			}
			.detail {
				padding: get-vw-mo(50px) get-vw-mo(40px);
				background-color: #fff;
				p {
					white-space: pre-line;
					@include font($NotoSansDL, get-vw-mo(28px), 1.4, #666)
				}
				h2 {
					font-size: get-vw-mo(42px);
				}
				h3 {
					font-size: get-vw-mo(32px);
				}
				h4 {
					font-size: get-vw-mo(30px);
				}
	
				.text-tiny {
					font-size: get-vw-mo(19px);
				}
				.text-small {
					font-size: get-vw-mo(23px);
				}
				.text-big {
					font-size: get-vw-mo(39px);
				}
				.text-huge {
					font-size: get-vw-mo(50px);
				}
				strong {
					font-weight: 700;
				}
				blockquote {
					overflow: hidden;
					padding-right: 1.5em;
					padding-left: 1.5em;
					margin-left: 0;
					margin-right: 0;
					font-style: italic;
					border-left: 5px solid #ccc;
				}
				ol {
					list-style-type: decimal;
					padding-left: 21px;
					@include font($NotoSansDL, get-vw-mo(28px), 1.4, #666)
				}
				ul {
					list-style-type: circle;
					padding-left: 21px;
					@include font($NotoSansDL, get-vw-mo(28px), 1.4, #666)
				}
			}
			&.on {
				.ico01 {
					transform:rotateZ(0)
				}
			}
		}

	}
}


// 태블릿
@media screen and (min-width:750px) and (max-width:1023px) {
	.tab-menu-type01 {
		width: 118px;
		height: 62px;
		padding: 7px;
		margin: 30px auto 0;
		border-radius: 31px;
		.list {
			// margin: 5px 0px 0px 10px;
			width: 18px;height: 18px;
		}
		.card {
			// margin: 0px 0px 0px 29px;
			width: 22px;height: 17px;
		}
		.round {
			top: 7px;left: 7px;
			width: 44px;height: 44px;
			&.card-type {
				right:7px;
				bottom: 7px;
				left: inherit;
				top: inherit;
			}
		}
	}
	.tab-menu-type02 {
		margin-bottom: 70px;
		button {
			padding-bottom: 30px;
			.txt {
				font-size: 32px;
			}
			&.on {
				padding-bottom: 25px;
				border-bottom: 5px solid #000;
			}
		}
	}

	/*! accordion */
	.ui-accordion {
		.accordion-item {
			.btn {
				padding: 37px 20px;
				.ico-q {
					margin-right: 20px;
					font-size: 36px;
				}
				.txt {
					font-size: 32px;
				}
				.ico01, .ico02 {
					right:20px;
					width:31px;
					height:3px;
				}
			}
			.detail {
				padding: 50px 40px;
				p {
					font-size: 28px;
					white-space: pre-line;
				}
				ol, ul {
					list-style: inherit;
					padding-left:  17px;
					@include font($NotoSansDL, 28px, 1.4, #666)
				}
			}
		}

	}
	.type-mo{
		.paging-util {
			width: 66px;height: 66px;
			line-height: 66px;
		}
		.paging-num{
			width: 66px;height: 66px;
			box-sizing: border-box;
			font-size: 32px;
			// line-height: 66px;
			.txt{font-size: 32px;
				line-height: 66px;}
			&.on{border:3px solid #222;}
			&.on .txt{font-size: 32px;
				line-height: 60px;}
	
		}
	}
}

@media screen and (max-width:414px){
	.tab-menu-type01 {
		.list {
			// margin: 8px 0 0 6px;
			vertical-align: top;
		}
		.card {
			// margin: 8px 0 0 10px;
			vertical-align: top;
		}
	}
}

// /*!
// 	page navigation - 태블릿 기본 스타일
// */
// @media screen and (min-width:768px) and (max-width:1024px){
//
// }
//
// /*!
// 	page navigation - 태블릿 가로모드 스타일
// */
// @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
//
// }
