// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$pc-btn-card-name: 'pc_btn_card';
$pc-btn-card-x: 22px;
$pc-btn-card-y: 201px;
$pc-btn-card-offset-x: -22px;
$pc-btn-card-offset-y: -201px;
$pc-btn-card-width: 10px;
$pc-btn-card-height: 10px;
$pc-btn-card-total-width: 219px;
$pc-btn-card-total-height: 211px;
$pc-btn-card-image: '../../images/common/sprite_pc.png';
$pc-btn-card: (22px, 201px, -22px, -201px, 10px, 10px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_btn_card', );
$pc-btn-list-name: 'pc_btn_list';
$pc-btn-list-x: 0px;
$pc-btn-list-y: 201px;
$pc-btn-list-offset-x: 0px;
$pc-btn-list-offset-y: -201px;
$pc-btn-list-width: 12px;
$pc-btn-list-height: 10px;
$pc-btn-list-total-width: 219px;
$pc-btn-list-total-height: 211px;
$pc-btn-list-image: '../../images/common/sprite_pc.png';
$pc-btn-list: (0px, 201px, 0px, -201px, 12px, 10px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_btn_list', );
$pc-etc-arr1-name: 'pc_etc_arr1';
$pc-etc-arr1-x: 210px;
$pc-etc-arr1-y: 100px;
$pc-etc-arr1-offset-x: -210px;
$pc-etc-arr1-offset-y: -100px;
$pc-etc-arr1-width: 8px;
$pc-etc-arr1-height: 12px;
$pc-etc-arr1-total-width: 219px;
$pc-etc-arr1-total-height: 211px;
$pc-etc-arr1-image: '../../images/common/sprite_pc.png';
$pc-etc-arr1: (210px, 100px, -210px, -100px, 8px, 12px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_etc_arr1', );
$pc-etc-arr2-name: 'pc_etc_arr2';
$pc-etc-arr2-x: 210px;
$pc-etc-arr2-y: 122px;
$pc-etc-arr2-offset-x: -210px;
$pc-etc-arr2-offset-y: -122px;
$pc-etc-arr2-width: 8px;
$pc-etc-arr2-height: 12px;
$pc-etc-arr2-total-width: 219px;
$pc-etc-arr2-total-height: 211px;
$pc-etc-arr2-image: '../../images/common/sprite_pc.png';
$pc-etc-arr2: (210px, 122px, -210px, -122px, 8px, 12px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_etc_arr2', );
$pc-ico-age-name: 'pc_ico_age';
$pc-ico-age-x: 62px;
$pc-ico-age-y: 129px;
$pc-ico-age-offset-x: -62px;
$pc-ico-age-offset-y: -129px;
$pc-ico-age-width: 20px;
$pc-ico-age-height: 20px;
$pc-ico-age-total-width: 219px;
$pc-ico-age-total-height: 211px;
$pc-ico-age-image: '../../images/common/sprite_pc.png';
$pc-ico-age: (62px, 129px, -62px, -129px, 20px, 20px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_age', );
$pc-ico-detail-name: 'pc_ico_detail';
$pc-ico-detail-x: 129px;
$pc-ico-detail-y: 84px;
$pc-ico-detail-offset-x: -129px;
$pc-ico-detail-offset-y: -84px;
$pc-ico-detail-width: 24px;
$pc-ico-detail-height: 24px;
$pc-ico-detail-total-width: 219px;
$pc-ico-detail-total-height: 211px;
$pc-ico-detail-image: '../../images/common/sprite_pc.png';
$pc-ico-detail: (129px, 84px, -129px, -84px, 24px, 24px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_detail', );
$pc-ico-down-name: 'pc_ico_down';
$pc-ico-down-x: 120px;
$pc-ico-down-y: 129px;
$pc-ico-down-offset-x: -120px;
$pc-ico-down-offset-y: -129px;
$pc-ico-down-width: 15px;
$pc-ico-down-height: 17px;
$pc-ico-down-total-width: 219px;
$pc-ico-down-total-height: 211px;
$pc-ico-down-image: '../../images/common/sprite_pc.png';
$pc-ico-down: (120px, 129px, -120px, -129px, 15px, 17px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_down', );
$pc-ico-download-dark-name: 'pc_ico_download_dark';
$pc-ico-download-dark-x: 92px;
$pc-ico-download-dark-y: 129px;
$pc-ico-download-dark-offset-x: -92px;
$pc-ico-download-dark-offset-y: -129px;
$pc-ico-download-dark-width: 18px;
$pc-ico-download-dark-height: 17px;
$pc-ico-download-dark-total-width: 219px;
$pc-ico-download-dark-total-height: 211px;
$pc-ico-download-dark-image: '../../images/common/sprite_pc.png';
$pc-ico-download-dark: (92px, 129px, -92px, -129px, 18px, 17px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_download_dark', );
$pc-ico-facebook-name: 'pc_ico_facebook';
$pc-ico-facebook-x: 84px;
$pc-ico-facebook-y: 0px;
$pc-ico-facebook-offset-x: -84px;
$pc-ico-facebook-offset-y: 0px;
$pc-ico-facebook-width: 74px;
$pc-ico-facebook-height: 74px;
$pc-ico-facebook-total-width: 219px;
$pc-ico-facebook-total-height: 211px;
$pc-ico-facebook-image: '../../images/common/sprite_pc.png';
$pc-ico-facebook: (84px, 0px, -84px, 0px, 74px, 74px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_facebook', );
$pc-ico-home-name: 'pc_ico_home';
$pc-ico-home-x: 45px;
$pc-ico-home-y: 84px;
$pc-ico-home-offset-x: -45px;
$pc-ico-home-offset-y: -84px;
$pc-ico-home-width: 32px;
$pc-ico-home-height: 32px;
$pc-ico-home-total-width: 219px;
$pc-ico-home-total-height: 211px;
$pc-ico-home-image: '../../images/common/sprite_pc.png';
$pc-ico-home: (45px, 84px, -45px, -84px, 32px, 32px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_home', );
$pc-ico-insta-name: 'pc_ico_insta';
$pc-ico-insta-x: 0px;
$pc-ico-insta-y: 0px;
$pc-ico-insta-offset-x: 0px;
$pc-ico-insta-offset-y: 0px;
$pc-ico-insta-width: 74px;
$pc-ico-insta-height: 74px;
$pc-ico-insta-total-width: 219px;
$pc-ico-insta-total-height: 211px;
$pc-ico-insta-image: '../../images/common/sprite_pc.png';
$pc-ico-insta: (0px, 0px, 0px, 0px, 74px, 74px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_insta', );
$pc-ico-kids-name: 'pc_ico_kids';
$pc-ico-kids-x: 0px;
$pc-ico-kids-y: 129px;
$pc-ico-kids-offset-x: 0px;
$pc-ico-kids-offset-y: -129px;
$pc-ico-kids-width: 20px;
$pc-ico-kids-height: 28px;
$pc-ico-kids-total-width: 219px;
$pc-ico-kids-total-height: 211px;
$pc-ico-kids-image: '../../images/common/sprite_pc.png';
$pc-ico-kids: (0px, 129px, 0px, -129px, 20px, 28px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_kids', );
$pc-ico-location-div-name: 'pc_ico_location_div';
$pc-ico-location-div-x: 210px;
$pc-ico-location-div-y: 144px;
$pc-ico-location-div-offset-x: -210px;
$pc-ico-location-div-offset-y: -144px;
$pc-ico-location-div-width: 8px;
$pc-ico-location-div-height: 10px;
$pc-ico-location-div-total-width: 219px;
$pc-ico-location-div-total-height: 211px;
$pc-ico-location-div-image: '../../images/common/sprite_pc.png';
$pc-ico-location-div: (210px, 144px, -210px, -144px, 8px, 10px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_location_div', );
$pc-ico-marker-name: 'pc_ico_marker';
$pc-ico-marker-x: 0px;
$pc-ico-marker-y: 167px;
$pc-ico-marker-offset-x: 0px;
$pc-ico-marker-offset-y: -167px;
$pc-ico-marker-width: 18px;
$pc-ico-marker-height: 24px;
$pc-ico-marker-total-width: 219px;
$pc-ico-marker-total-height: 211px;
$pc-ico-marker-image: '../../images/common/sprite_pc.png';
$pc-ico-marker: (0px, 167px, 0px, -167px, 18px, 24px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_marker', );
$pc-ico-pagi-first-name: 'pc_ico_pagi_first';
$pc-ico-pagi-first-x: 170px;
$pc-ico-pagi-first-y: 129px;
$pc-ico-pagi-first-offset-x: -170px;
$pc-ico-pagi-first-offset-y: -129px;
$pc-ico-pagi-first-width: 15px;
$pc-ico-pagi-first-height: 16px;
$pc-ico-pagi-first-total-width: 219px;
$pc-ico-pagi-first-total-height: 211px;
$pc-ico-pagi-first-image: '../../images/common/sprite_pc.png';
$pc-ico-pagi-first: (170px, 129px, -170px, -129px, 15px, 16px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_pagi_first', );
$pc-ico-pagi-last-name: 'pc_ico_pagi_last';
$pc-ico-pagi-last-x: 145px;
$pc-ico-pagi-last-y: 129px;
$pc-ico-pagi-last-offset-x: -145px;
$pc-ico-pagi-last-offset-y: -129px;
$pc-ico-pagi-last-width: 15px;
$pc-ico-pagi-last-height: 16px;
$pc-ico-pagi-last-total-width: 219px;
$pc-ico-pagi-last-total-height: 211px;
$pc-ico-pagi-last-image: '../../images/common/sprite_pc.png';
$pc-ico-pagi-last: (145px, 129px, -145px, -129px, 15px, 16px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_pagi_last', );
$pc-ico-pagi-next-name: 'pc_ico_pagi_next';
$pc-ico-pagi-next-x: 210px;
$pc-ico-pagi-next-y: 0px;
$pc-ico-pagi-next-offset-x: -210px;
$pc-ico-pagi-next-offset-y: 0px;
$pc-ico-pagi-next-width: 9px;
$pc-ico-pagi-next-height: 16px;
$pc-ico-pagi-next-total-width: 219px;
$pc-ico-pagi-next-total-height: 211px;
$pc-ico-pagi-next-image: '../../images/common/sprite_pc.png';
$pc-ico-pagi-next: (210px, 0px, -210px, 0px, 9px, 16px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_pagi_next', );
$pc-ico-pagi-prev-name: 'pc_ico_pagi_prev';
$pc-ico-pagi-prev-x: 210px;
$pc-ico-pagi-prev-y: 26px;
$pc-ico-pagi-prev-offset-x: -210px;
$pc-ico-pagi-prev-offset-y: -26px;
$pc-ico-pagi-prev-width: 9px;
$pc-ico-pagi-prev-height: 16px;
$pc-ico-pagi-prev-total-width: 219px;
$pc-ico-pagi-prev-total-height: 211px;
$pc-ico-pagi-prev-image: '../../images/common/sprite_pc.png';
$pc-ico-pagi-prev: (210px, 26px, -210px, -26px, 9px, 16px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_pagi_prev', );
$pc-ico-paginav-first-name: 'pc_ico_paginav_first';
$pc-ico-paginav-first-x: 28px;
$pc-ico-paginav-first-y: 167px;
$pc-ico-paginav-first-offset-x: -28px;
$pc-ico-paginav-first-offset-y: -167px;
$pc-ico-paginav-first-width: 14px;
$pc-ico-paginav-first-height: 14px;
$pc-ico-paginav-first-total-width: 219px;
$pc-ico-paginav-first-total-height: 211px;
$pc-ico-paginav-first-image: '../../images/common/sprite_pc.png';
$pc-ico-paginav-first: (28px, 167px, -28px, -167px, 14px, 14px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_paginav_first', );
$pc-ico-paginav-last-name: 'pc_ico_paginav_last';
$pc-ico-paginav-last-x: 52px;
$pc-ico-paginav-last-y: 167px;
$pc-ico-paginav-last-offset-x: -52px;
$pc-ico-paginav-last-offset-y: -167px;
$pc-ico-paginav-last-width: 14px;
$pc-ico-paginav-last-height: 14px;
$pc-ico-paginav-last-total-width: 219px;
$pc-ico-paginav-last-total-height: 211px;
$pc-ico-paginav-last-image: '../../images/common/sprite_pc.png';
$pc-ico-paginav-last: (52px, 167px, -52px, -167px, 14px, 14px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_paginav_last', );
$pc-ico-paginav-next-name: 'pc_ico_paginav_next';
$pc-ico-paginav-next-x: 210px;
$pc-ico-paginav-next-y: 52px;
$pc-ico-paginav-next-offset-x: -210px;
$pc-ico-paginav-next-offset-y: -52px;
$pc-ico-paginav-next-width: 8px;
$pc-ico-paginav-next-height: 14px;
$pc-ico-paginav-next-total-width: 219px;
$pc-ico-paginav-next-total-height: 211px;
$pc-ico-paginav-next-image: '../../images/common/sprite_pc.png';
$pc-ico-paginav-next: (210px, 52px, -210px, -52px, 8px, 14px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_paginav_next', );
$pc-ico-paginav-prev-name: 'pc_ico_paginav_prev';
$pc-ico-paginav-prev-x: 210px;
$pc-ico-paginav-prev-y: 76px;
$pc-ico-paginav-prev-offset-x: -210px;
$pc-ico-paginav-prev-offset-y: -76px;
$pc-ico-paginav-prev-width: 8px;
$pc-ico-paginav-prev-height: 14px;
$pc-ico-paginav-prev-total-width: 219px;
$pc-ico-paginav-prev-total-height: 211px;
$pc-ico-paginav-prev-image: '../../images/common/sprite_pc.png';
$pc-ico-paginav-prev: (210px, 76px, -210px, -76px, 8px, 14px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_paginav_prev', );
$pc-ico-selectbox-down-name: 'pc_ico_selectbox_down';
$pc-ico-selectbox-down-x: 76px;
$pc-ico-selectbox-down-y: 167px;
$pc-ico-selectbox-down-offset-x: -76px;
$pc-ico-selectbox-down-offset-y: -167px;
$pc-ico-selectbox-down-width: 22px;
$pc-ico-selectbox-down-height: 6px;
$pc-ico-selectbox-down-total-width: 219px;
$pc-ico-selectbox-down-total-height: 211px;
$pc-ico-selectbox-down-image: '../../images/common/sprite_pc.png';
$pc-ico-selectbox-down: (76px, 167px, -76px, -167px, 22px, 6px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_selectbox_down', );
$pc-ico-selectbox-up-name: 'pc_ico_selectbox_up';
$pc-ico-selectbox-up-x: 108px;
$pc-ico-selectbox-up-y: 167px;
$pc-ico-selectbox-up-offset-x: -108px;
$pc-ico-selectbox-up-offset-y: -167px;
$pc-ico-selectbox-up-width: 22px;
$pc-ico-selectbox-up-height: 6px;
$pc-ico-selectbox-up-total-width: 219px;
$pc-ico-selectbox-up-total-height: 211px;
$pc-ico-selectbox-up-image: '../../images/common/sprite_pc.png';
$pc-ico-selectbox-up: (108px, 167px, -108px, -167px, 22px, 6px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_selectbox_up', );
$pc-ico-time-name: 'pc_ico_time';
$pc-ico-time-x: 30px;
$pc-ico-time-y: 129px;
$pc-ico-time-offset-x: -30px;
$pc-ico-time-offset-y: -129px;
$pc-ico-time-width: 22px;
$pc-ico-time-height: 22px;
$pc-ico-time-total-width: 219px;
$pc-ico-time-total-height: 211px;
$pc-ico-time-image: '../../images/common/sprite_pc.png';
$pc-ico-time: (30px, 129px, -30px, -129px, 22px, 22px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_time', );
$pc-ico-youtube-name: 'pc_ico_youtube';
$pc-ico-youtube-x: 0px;
$pc-ico-youtube-y: 84px;
$pc-ico-youtube-offset-x: 0px;
$pc-ico-youtube-offset-y: -84px;
$pc-ico-youtube-width: 35px;
$pc-ico-youtube-height: 35px;
$pc-ico-youtube-total-width: 219px;
$pc-ico-youtube-total-height: 211px;
$pc-ico-youtube-image: '../../images/common/sprite_pc.png';
$pc-ico-youtube: (0px, 84px, 0px, -84px, 35px, 35px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_ico_youtube', );
$pc-icon-blog-name: 'pc_icon_blog';
$pc-icon-blog-x: 87px;
$pc-icon-blog-y: 84px;
$pc-icon-blog-offset-x: -87px;
$pc-icon-blog-offset-y: -84px;
$pc-icon-blog-width: 32px;
$pc-icon-blog-height: 32px;
$pc-icon-blog-total-width: 219px;
$pc-icon-blog-total-height: 211px;
$pc-icon-blog-image: '../../images/common/sprite_pc.png';
$pc-icon-blog: (87px, 84px, -87px, -84px, 32px, 32px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_icon_blog', );
$pc-icon-face-name: 'pc_icon_face';
$pc-icon-face-x: 168px;
$pc-icon-face-y: 0px;
$pc-icon-face-offset-x: -168px;
$pc-icon-face-offset-y: 0px;
$pc-icon-face-width: 32px;
$pc-icon-face-height: 32px;
$pc-icon-face-total-width: 219px;
$pc-icon-face-total-height: 211px;
$pc-icon-face-image: '../../images/common/sprite_pc.png';
$pc-icon-face: (168px, 0px, -168px, 0px, 32px, 32px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_icon_face', );
$pc-icon-insta-name: 'pc_icon_insta';
$pc-icon-insta-x: 168px;
$pc-icon-insta-y: 42px;
$pc-icon-insta-offset-x: -168px;
$pc-icon-insta-offset-y: -42px;
$pc-icon-insta-width: 32px;
$pc-icon-insta-height: 32px;
$pc-icon-insta-total-width: 219px;
$pc-icon-insta-total-height: 211px;
$pc-icon-insta-image: '../../images/common/sprite_pc.png';
$pc-icon-insta: (168px, 42px, -168px, -42px, 32px, 32px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_icon_insta', );
$pc-icon-youtu-name: 'pc_icon_youtu';
$pc-icon-youtu-x: 168px;
$pc-icon-youtu-y: 84px;
$pc-icon-youtu-offset-x: -168px;
$pc-icon-youtu-offset-y: -84px;
$pc-icon-youtu-width: 32px;
$pc-icon-youtu-height: 32px;
$pc-icon-youtu-total-width: 219px;
$pc-icon-youtu-total-height: 211px;
$pc-icon-youtu-image: '../../images/common/sprite_pc.png';
$pc-icon-youtu: (168px, 84px, -168px, -84px, 32px, 32px, 219px, 211px, '../../images/common/sprite_pc.png', 'pc_icon_youtu', );
$spritesheet-width: 219px;
$spritesheet-height: 211px;
$spritesheet-image: '../../images/common/sprite_pc.png';
$spritesheet-sprites: ($pc-btn-card, $pc-btn-list, $pc-etc-arr1, $pc-etc-arr2, $pc-ico-age, $pc-ico-detail, $pc-ico-down, $pc-ico-download-dark, $pc-ico-facebook, $pc-ico-home, $pc-ico-insta, $pc-ico-kids, $pc-ico-location-div, $pc-ico-marker, $pc-ico-pagi-first, $pc-ico-pagi-last, $pc-ico-pagi-next, $pc-ico-pagi-prev, $pc-ico-paginav-first, $pc-ico-paginav-last, $pc-ico-paginav-next, $pc-ico-paginav-prev, $pc-ico-selectbox-down, $pc-ico-selectbox-up, $pc-ico-time, $pc-ico-youtube, $pc-icon-blog, $pc-icon-face, $pc-icon-insta, $pc-icon-youtu, );
$spritesheet: (219px, 211px, '../../images/common/sprite_pc.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
