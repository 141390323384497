@charset "UTF-8";
/**
	* Computes a CSS calc function that betweens a value from
	* A to B over viewport-width A to viewport-width B.
	* Requires a media query to cap the value at B.
	* vw 단위를 활용하여 미디어쿼리 분기의 중간 상태값을
	* 계산해주는 SASS 함수.
	* 간격이나 폰트사이즈에 적용하면 자연스러운
	* 반응형 사이즈 처리가 가능 함.
	* 참고사이트 http://dohoons.com/blog/1364
	*/
/**
	* Read the article: http://sassbreak.com/viewport-relative-headings-with-sass
	* px 를 vw 로 자동으로 바꿔 줌.
	* max-breakpoint 를 설정하면 미디어쿼리 브레이크 포인트가 됨.
	* 그래서 미디어쿼리 브레이크 포인트에 맞춰 px 가 vw 로 변환됨.
	* 100% 보다 더 크게 적용 하고 싶으면 return 부분을 수정하면됨.
	* 참고사이트 http://sassbreak.com/viewport-relative-headings-with-sass/
	*/
/*! common */
.clfix:after, .clfix:before {
  display: table;
  line-height: 0;
  content: ""; }

.clfix:after {
  clear: both; }

.hide-txt {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  font-size: 1px;
  line-height: 0;
  clip: rect(0 0 0 0);
  white-space: nowrap;
  margin: -1px;
  padding: 0;
  border: 0; }

@media (max-width: 1024px) {
  a .hide-txt {
    font-size: 0; } }

a:before {
  content: '　';
  position: absolute;
  font-size: 0; }

/*! skipnav */
.skipnav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 0;
  line-height: 1; }

.skipnav a {
  display: block;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: 0 -1px -1px 0;
  padding: 0;
  font-size: 0;
  line-height: 1; }

.skipnav a:active, .skipnav a:focus, .skipnav a:hover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 19px;
  margin: 0;
  padding: 5px 0;
  border: 1px solid #c4d7ea;
  background: #2b6cb0;
  color: #fff;
  font-weight: 700;
  font-size: 1.333em;
  text-align: center;
  line-height: 100%; }

/*! Browser Upgrade Prompt */
.browserupgrade {
  margin: .2em 0;
  padding: .2em 0;
  background: #ccc;
  color: #000;
  font-size: 1px; }

.browserupgrade strong {
  color: #000;
  font-weight: 700; }

.browserupgrade a {
  color: #8d0080;
  font-weight: 700;
  text-decoration: underline; }

/*! dimm */
.dimm {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: #000;
  opacity: .6; }

.dimm img {
  display: block; }

/*do not work*/
.doNotWork {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999; }

/*! plugin */
/*! btn */
.bttn {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer; }

.bttn.type01 {
  padding: 10px 20px 10px 20px;
  line-height: 30px;
  text-align: center;
  border-radius: 20px; }

.bttn.type02 {
  padding: 10px 20px 10px 20px;
  line-height: 30px;
  text-align: center; }

.bttn.type03 {
  min-width: 280px;
  padding: 10px 10px 10px 10px;
  line-height: 30px;
  text-align: left; }

.bttn.type04 {
  min-width: 280px;
  padding: 10px 10px 10px 10px;
  line-height: 30px;
  text-align: right; }

.bttn.style01 {
  background: orange;
  border: 1px solid orange; }

.bttn.style02 {
  background: lightcoral;
  border: 1px solid lightcoral; }

.bttn .txt01 {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #000000;
  line-height: 1.2; }

.bttn .ico01 {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  background-image: url(../../images/common/sprite_pc.png);
  background-position: -52px -167px;
  width: 14px;
  height: 14px;
  background-size: 219px 211px; }

.bttn .ico02 {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  background-image: url(../../images/common/sprite_pc.png);
  background-position: -28px -167px;
  width: 14px;
  height: 14px;
  background-size: 219px 211px; }

.bttn .ico03 {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
  background-image: url(../../images/common/sprite_pc.png);
  background-position: -52px -167px;
  width: 14px;
  height: 14px;
  background-size: 219px 211px; }

.bttn .ico04 {
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -8px;
  background-image: url(../../images/common/sprite_pc.png);
  background-position: -28px -167px;
  width: 14px;
  height: 14px;
  background-size: 219px 211px; }

/*! form */
.input-checkbox {
  display: inline-block;
  height: 20px; }
  .input-checkbox input {
    border: 0;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute; }
  .input-checkbox .txt {
    display: inline-block;
    line-height: 1;
    color: #000000;
    font-size: 14px; }
  .input-checkbox label {
    display: inline-block;
    padding: 0 10px 0 10px;
    position: relative;
    user-select: none; }
  .input-checkbox label:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    border: 2px solid grey;
    text-align: center; }
  .input-checkbox label:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 5px;
    width: 8px;
    height: 3px;
    margin-top: -4px;
    border-top: 0;
    border-right: 0;
    border-bottom: 3px solid #900900;
    border-left: 3px solid #900900;
    transform: rotate(-45deg); }
  .input-checkbox.right label {
    padding: 0 0 0 24px; }
  .input-checkbox.right label:before {
    margin-top: -12px; }
  .input-checkbox.right label:after {
    margin-top: -6px; }
  .input-checkbox.left label {
    padding: 0 24px 0 0; }
  .input-checkbox.left label:before {
    left: auto;
    right: 0;
    margin-top: -12px; }
  .input-checkbox.left label:after {
    left: auto;
    right: 4px;
    margin-top: -6px; }
  .input-checkbox.up label {
    padding: 0 0 20px 0; }
  .input-checkbox.up label:before {
    top: auto;
    bottom: 0;
    left: 50%;
    margin-left: -10px;
    margin-top: 0; }
  .input-checkbox.up label:after {
    top: auto;
    bottom: 8px;
    left: 50%;
    margin-left: -5px;
    margin-top: 0; }
  .input-checkbox.down label {
    padding: 20px 0 0 0; }
  .input-checkbox.down label:before {
    top: 0;
    left: 50%;
    margin-left: -10px;
    margin-top: 0; }
  .input-checkbox.down label:after {
    top: 6px;
    left: 50%;
    margin-left: -5px;
    margin-top: 0; }
  .input-checkbox input:focus + label:before {
    border-color: black; }
  .input-checkbox input:hover + label:before {
    border-color: black; }
  .input-checkbox input:disabled:focus + label:before {
    border-color: grey; }
  .input-checkbox input:disabled:hover + label:before {
    border-color: grey; }
  .input-checkbox input:disabled + label:before {
    opacity: 0.5; }
  .input-checkbox input:disabled + label:after {
    opacity: 0.5; }
  .input-checkbox label:after {
    transform: rotate(-45deg) scale(0);
    transition: none; }
  .input-checkbox input:checked + label:after {
    transform: rotate(-45deg) scale(1);
    transition: transform 200ms ease-out; }

.input-radio {
  display: inline-block;
  height: 20px; }
  .input-radio input {
    border: 0;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute; }
  .input-radio .txt {
    display: inline-block;
    line-height: 1;
    color: #000000;
    font-size: 14px; }
  .input-radio label {
    display: inline-block;
    position: relative;
    padding: 0 10px 0 10px;
    user-select: none; }
  .input-radio label:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    border: 2px solid grey;
    text-align: center;
    border-radius: 50%; }
  .input-radio label:after {
    content: '';
    background-color: #900;
    position: absolute;
    top: 50%;
    left: 5px;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    border-radius: 50%; }
  .input-radio.right label {
    padding: 0 0 0 24px; }
  .input-radio.right label:before {
    margin-top: -12px; }
  .input-radio.right label:after {
    margin-top: -7px; }
  .input-radio.left label {
    padding: 0 24px 0 0; }
  .input-radio.left label:before {
    left: auto;
    right: 0;
    margin-top: -12px; }
  .input-radio.left label:after {
    left: auto;
    right: 5px;
    margin-top: -7px; }
  .input-radio.up label {
    padding: 0 0 20px 0; }
  .input-radio.up label:before {
    top: auto;
    bottom: 0;
    left: 50%;
    margin-left: -10px;
    margin-top: 0; }
  .input-radio.up label:after {
    top: auto;
    bottom: 5px;
    left: 50%;
    margin-left: -5px;
    margin-top: 0; }
  .input-radio.down label {
    padding: 20px 0 0 0; }
  .input-radio.down label:before {
    top: 0;
    left: 50%;
    margin-left: -10px;
    margin-top: 0; }
  .input-radio.down label:after {
    top: 5px;
    left: 50%;
    margin-left: -5px;
    margin-top: 0; }
  .input-radio input:focus + label:before {
    border-color: black; }
  .input-radio input:hover + label:before {
    border-color: black; }
  .input-radio input:disabled:focus + label:before {
    border-color: grey; }
  .input-radio input:disabled:hover + label:before {
    border-color: grey; }
  .input-radio input:disabled + label:before {
    opacity: 0.5; }
  .input-radio input:disabled + label:after {
    opacity: 0.5; }
  .input-radio label:after {
    transform: rotate(-45deg) scale(0);
    transition: none; }
  .input-radio input:checked + label:after {
    transform: rotate(-45deg) scale(1);
    transition: transform 200ms ease-out; }

.input-txt {
  display: inline-block;
  position: relative;
  width: 100%;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  background-color: #ffffff; }
  .input-txt input {
    display: inline-block;
    padding: 0 0 0 15px;
    width: 100%;
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    border: none;
    box-sizing: border-box;
    appearance: none;
    border-radius: 0; }

.input-txt input:read-only {
  opacity: 0.5; }

.input-txt input::placeholder {
  font-size: 12px;
  color: #999999;
  background: transparent;
  opacity: 1; }

.input-txt input:-ms-input-placeholder {
  position: relative;
  top: 1px; }

.input-txt input::-ms-input-placeholder {
  position: relative;
  top: 1px; }

.selectbox {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  border: 1px solid #cccccc;
  box-sizing: border-box; }
  .selectbox:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -3px;
    background-image: url(../../images/common/sprite_pc.png);
    background-position: -76px -167px;
    width: 22px;
    height: 6px;
    background-size: 219px 211px; }
  .selectbox select {
    vertical-align: middle;
    border: none;
    appearance: none;
    border-radius: 0;
    width: 100%;
    padding: 0 0 0 15px;
    box-sizing: border-box;
    height: 38px;
    line-height: 38px;
    background-color: transparent;
    position: relative;
    z-index: 1; }
  .selectbox select::-ms-expand {
    display: none; }
  .selectbox select:disabled {
    opacity: 0.5; }

.ie9 select {
  width: calc(100% + 20px);
  padding: 12px 0 10px 15px;
  height: auto; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .selectbox select {
    padding: 12px 0 10px 15px;
    height: auto; } }

/*! page navigation */
.paginav {
  text-align: center; }

.paging-util {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: top; }
  .paging-util .icon {
    position: absolute;
    top: 50%;
    left: 50%; }

.btn-paging-first .icon {
  background-image: url(../../images/common/sprite_pc.png);
  background-position: -170px -129px;
  width: 15px;
  height: 16px;
  background-size: 219px 211px;
  margin: -7px 0 0 -7px; }

.btn-paging-prev .icon {
  background-image: url(../../images/common/sprite_pc.png);
  background-position: -210px -26px;
  width: 9px;
  height: 16px;
  background-size: 219px 211px;
  margin: -7px 0 0 -4px; }

.btn-paging-next .icon {
  background-image: url(../../images/common/sprite_pc.png);
  background-position: -210px 0px;
  width: 9px;
  height: 16px;
  background-size: 219px 211px;
  margin: -7px 0 0 -4px; }

.btn-paging-last .icon {
  background-image: url(../../images/common/sprite_pc.png);
  background-position: -145px -129px;
  width: 15px;
  height: 16px;
  background-size: 219px 211px;
  margin: -7px 0 0 -7px; }

.paging-list {
  display: inline-block;
  vertical-align: top; }

.paging-num {
  display: inline-block;
  width: 36px;
  height: 36px;
  vertical-align: top;
  border-radius: 100%;
  box-sizing: border-box; }
  .paging-num .txt {
    font-family: "NotoSans-DemiLight";
    font-size: 18px;
    line-height: 36px;
    color: #999; }
  .paging-num.on {
    border: 2px solid #222; }
  .paging-num.on .txt {
    font-family: "NotoSans-Medium";
    font-size: 18px;
    line-height: 32px;
    color: #222; }

.type-mo .paging-util {
  width: 8.8vw;
  height: 8.8vw;
  line-height: 8.8vw; }

.type-mo .paging-num {
  width: 8.8vw;
  height: 8.8vw;
  box-sizing: border-box;
  font-family: "NotoSans-DemiLight";
  font-size: 4.26667vw;
  line-height: normal;
  color: #999; }
  .type-mo .paging-num .txt {
    font-family: "NotoSans-DemiLight";
    font-size: 4.26667vw;
    line-height: 8.8vw;
    color: #999; }
  .type-mo .paging-num.on {
    border: 0.4vw solid #222; }
  .type-mo .paging-num.on .txt {
    font-family: "NotoSans-Medium";
    font-size: 4.26667vw;
    line-height: 8.26667vw;
    color: #222; }

/*! map api */
.map-api {
  width: 300px;
  height: 300px;
  background: #000; }

/*! movie */
.movie-area {
  width: 300px;
  height: 300px; }
  .movie-area iframe {
    border: none; }
  .movie-area video {
    width: 100%;
    height: 100%; }

/*! tab menu */
.tab-menu-type01 {
  position: relative;
  width: 30px;
  height: 58px;
  padding: 4px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  cursor: pointer; }
  .tab-menu-type01 .list {
    display: block;
    width: 11px;
    height: 11px;
    margin: 0 auto;
    background-image: url(../../images/common/sprite_pc.png);
    background-position: 0px -201px;
    width: 12px;
    height: 10px;
    background-size: 219px 211px; }
  .tab-menu-type01 .card {
    display: block;
    width: 10px;
    height: 10px;
    margin: 15px auto 0;
    background-image: url(../../images/common/sprite_pc.png);
    background-position: -22px -201px;
    width: 10px;
    height: 10px;
    background-size: 219px 211px; }
  .tab-menu-type01 .round {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 22px;
    height: 22px;
    background-color: #000;
    border-radius: 100%; }
    .tab-menu-type01 .round.card-type {
      top: inherit;
      bottom: 4px; }
  .tab-menu-type01 .transition {
    transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all; }

.tab-menu-type02 {
  margin-bottom: 50px; }
  .tab-menu-type02 button {
    width: 216px;
    padding-bottom: 19px;
    border-bottom: 1px solid #ddd; }
    .tab-menu-type02 button .txt {
      font-family: "NotoSans-Medium";
      font-size: 22px;
      color: #999; }
    .tab-menu-type02 button + button {
      margin-left: -4px; }
    .tab-menu-type02 button.on {
      padding-bottom: 15px;
      border-bottom: 4px solid #000; }
      .tab-menu-type02 button.on .txt {
        color: #222; }

/*! table */
.table-wrap-type01 {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 12px; }

.table-col-type01 {
  width: 100%;
  background: #edeef3; }
  .table-col-type01 th {
    color: #333130;
    text-align: center;
    border-left: 1px solid #ffffff;
    height: 42px; }
    .table-col-type01 th:first-child {
      border-left: 0; }
  .table-col-type01 th .txt {
    position: relative;
    top: -20px; }
  .table-col-type01 td {
    color: #4d4948;
    text-align: center;
    line-height: 2.22222222;
    border-left: 1px solid #ffffff;
    vertical-align: top; }
    .table-col-type01 td:first-child {
      border-left: 0; }
  .table-col-type01 td.left {
    text-align: left;
    padding-left: 40px; }
  .table-col-type01 td.middle {
    vertical-align: middle; }
  .table-col-type01 tr:last-child td {
    padding-bottom: 27px; }
  .table-col-type01 .btn-download-dark {
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    position: relative; }
    .table-col-type01 .btn-download-dark .ico {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -9px 0 0 -9px;
      background-image: url(../../images/common/sprite_pc.png);
      background-position: -92px -129px;
      width: 18px;
      height: 17px;
      background-size: 219px 211px; }
  .table-col-type01 .btn-link01 {
    display: inline-block;
    vertical-align: middle; }
    .table-col-type01 .btn-link01 .txt {
      color: #4d4948; }
    .table-col-type01 .btn-link01 .ico {
      display: inline-block;
      vertical-align: middle;
      background-image: url(../../images/common/sprite_pc.png);
      background-position: -92px -129px;
      width: 18px;
      height: 17px;
      background-size: 219px 211px; }

.table-wrap-type02 {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto; }

.table-col-type02 {
  width: 100%;
  table-layout: fixed; }
  .table-col-type02 thead {
    border: 1px solid #999290; }
  .table-col-type02 th {
    color: #333130;
    text-align: center;
    height: 57px; }
  .table-col-type02 td {
    color: #4d4948;
    text-align: center;
    line-height: 3.1;
    vertical-align: middle;
    height: 56px;
    border-bottom: 1px solid #cccccc; }
    .table-col-type02 td .txt-link {
      width: calc(100% - 10px);
      display: block;
      color: #4d4948; }
    .table-col-type02 td .line-clamp {
      display: block;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      position: relative;
      line-height: 3.1 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 3.1em;
      display: block !important;
      white-space: nowrap; }
      .table-col-type02 td .line-clamp:after {
        content: '...';
        text-align: right;
        bottom: 0;
        right: 0;
        width: 25%;
        display: block;
        position: absolute;
        height: 3.1em;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), white 75%); }
      .table-col-type02 td .line-clamp:after {
        display: none !important; }
      @supports (-webkit-line-clamp: 1) {
        .table-col-type02 td .line-clamp:after {
          display: none !important; } }
  .table-col-type02 td.left {
    text-align: left; }
  .table-col-type02 tr:first-child td {
    padding-top: 10px;
    height: 58px; }
  .table-col-type02 .btn-download-dark {
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    position: relative; }
    .table-col-type02 .btn-download-dark .ico {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -9px 0 0 -9px;
      background-image: url(../../images/common/sprite_pc.png);
      background-position: -92px -129px;
      width: 18px;
      height: 17px;
      background-size: 219px 211px; }

.colgroup-prototype01-01 .col01 {
  width: 160px; }

.colgroup-prototype01-01 .col02 {
  width: auto; }

.colgroup-prototype01-01 .col03 {
  width: 240px; }

.colgroup-prototype01-02 .col01 {
  width: 160px; }

.colgroup-prototype01-02 .col02 {
  width: auto; }

.colgroup-prototype01-02 .col03 {
  width: 240px; }

.colgroup-prototype01-03 .col01 {
  width: 160px; }

.colgroup-prototype01-03 .col02 {
  width: 160px; }

.colgroup-prototype01-03 .col03 {
  width: auto; }

.colgroup-prototype01-03 .col04 {
  width: 160px; }

.colgroup-prototype01-03 .col05 {
  width: 240px; }

.colgroup-prototype01-04 .col01 {
  width: auto; }

.colgroup-prototype01-04 .col02 {
  width: auto; }

.colgroup-prototype01-04 .col03 {
  width: auto; }

.colgroup-prototype01-04 .col04 {
  width: auto; }

.colgroup-prototype01-04 .col05 {
  width: auto; }

.colgroup-prototype02-01 .col01 {
  width: 110px; }

.colgroup-prototype02-01 .col02 {
  width: auto; }

.colgroup-prototype02-01 .col03 {
  width: 140px; }

.colgroup-prototype02-02 .col01 {
  width: 110px; }

.colgroup-prototype02-02 .col02 {
  width: auto; }

.colgroup-prototype02-02 .col03 {
  width: 140px; }

/*! prototype_mixin */
.location-wrp a {
  display: inline-block;
  padding-right: 20px;
  position: relative;
  color: #767676;
  font-size: 15px; }
  .location-wrp a:before {
    position: absolute;
    display: inline-block;
    content: '';
    background-image: url(../../images/common/sprite_pc.png);
    background-position: -210px -144px;
    width: 8px;
    height: 10px;
    background-size: 219px 211px;
    top: 4px;
    right: 4px; }
  .location-wrp a:last-child {
    color: #222;
    font-weight: bold; }
    .location-wrp a:last-child:before {
      display: none; }

.location-wrp2 > ul > li {
  float: left;
  position: relative; }
  .location-wrp2 > ul > li ul {
    display: none;
    position: absolute;
    left: 0;
    top: 20px; }
  .location-wrp2 > ul > li:last-child a {
    color: #222;
    font-weight: bold; }
    .location-wrp2 > ul > li:last-child a:before {
      display: none; }
  .location-wrp2 > ul > li > a {
    display: inline-block;
    padding-right: 20px;
    position: relative;
    color: #767676;
    font-size: 15px; }
    .location-wrp2 > ul > li > a:before {
      position: absolute;
      display: inline-block;
      content: '';
      background-image: url(../../images/common/sprite_pc.png);
      background-position: -210px -144px;
      width: 8px;
      height: 10px;
      background-size: 219px 211px;
      top: 4px;
      right: 4px; }
    .location-wrp2 > ul > li > a:last-child {
      color: #222;
      font-weight: bold; }
      .location-wrp2 > ul > li > a:last-child:before {
        display: none; }

/*! accordion */
.ui-accordion {
  width: 100%;
  border-top: 2px solid #222;
  padding-bottom: 40px; }
  .ui-accordion .accordion-item {
    border-bottom: 1px solid #ddd; }
    .ui-accordion .accordion-item .btn {
      position: relative;
      width: 100%;
      padding: 28px 29px;
      text-align: left;
      cursor: pointer; }
      .ui-accordion .accordion-item .btn .ico-q {
        display: inline-block;
        vertical-align: middle;
        font-family: "Barlow";
        font-weight: 600;
        font-size: 24px;
        line-height: 1.4;
        color: #00a551; }
      .ui-accordion .accordion-item .btn .txt {
        display: inline-block;
        word-break: keep-all;
        vertical-align: middle;
        font-family: "NotoSans-Medium";
        font-size: 22px;
        line-height: 1.5;
        color: #333; }
      .ui-accordion .accordion-item .btn .ico01, .ui-accordion .accordion-item .btn .ico02 {
        position: absolute;
        top: 43px;
        right: 30px;
        width: 20px;
        height: 2px;
        background: #222;
        transition: .4s all; }
      .ui-accordion .accordion-item .btn .ico01 {
        transform: rotateZ(90deg); }
    .ui-accordion .accordion-item .detail {
      display: none;
      padding: 35px 29px;
      text-align: left;
      background-color: #fff; }
      .ui-accordion .accordion-item .detail h2 {
        font-size: 1.5em; }
      .ui-accordion .accordion-item .detail h3 {
        font-size: 1.17em; }
      .ui-accordion .accordion-item .detail h4 {
        font-size: 13px; }
      .ui-accordion .accordion-item .detail .text-tiny {
        font-size: .7em; }
      .ui-accordion .accordion-item .detail .text-small {
        font-size: .85em; }
      .ui-accordion .accordion-item .detail .text-big {
        font-size: 1.4em; }
      .ui-accordion .accordion-item .detail .text-huge {
        font-size: 1.8em; }
      .ui-accordion .accordion-item .detail strong {
        font-weight: 700; }
      .ui-accordion .accordion-item .detail blockquote {
        overflow: hidden;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin-left: 0;
        margin-right: 0;
        font-style: italic;
        border-left: 5px solid #ccc; }
      .ui-accordion .accordion-item .detail ol {
        list-style-type: decimal;
        padding-left: 21px;
        font-family: "NotoSans-DemiLight";
        font-size: 16px;
        line-height: 1.75;
        color: #666; }
      .ui-accordion .accordion-item .detail ul {
        list-style-type: circle;
        padding-left: 21px;
        font-family: "NotoSans-DemiLight";
        font-size: 16px;
        line-height: 1.75;
        color: #666; }
      .ui-accordion .accordion-item .detail p {
        word-break: keep-all;
        white-space: pre-line;
        font-family: "NotoSans-DemiLight";
        font-size: 16px;
        line-height: 1.75;
        color: #666; }
    .ui-accordion .accordion-item.on .ico01 {
      transform: rotateZ(0); }

/*! table */
/*! mobile*/
/*! tab */
@media screen and (max-width: 1023px) {
  /*! tab menu */
  .main-tab-wrap {
    text-align: center; }
  .tab-menu-type01 {
    position: relative;
    width: 15.73333vw;
    height: 8.26667vw;
    padding: 0.93333vw;
    margin: 4vw auto 0vw;
    border-radius: 4.13333vw;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #e5e5e5; }
    .tab-menu-type01 .btn-wrap {
      display: flex; }
    .tab-menu-type01 .list {
      width: 2.4vw;
      height: 2.4vw;
      background: url(../../images/mo/btn_card.png);
      background-size: contain;
      margin: 0;
      position: absolute;
      left: 2.66667vw;
      top: 50%;
      transform: translateY(-50%); }
    .tab-menu-type01 .card {
      width: 2.93333vw;
      height: 2.26667vw;
      background: url(../../images/mo/btn_list.png);
      background-size: contain;
      margin: 0;
      position: absolute;
      right: 2.4vw;
      top: 50%;
      transform: translateY(-50%); }
    .tab-menu-type01 .round {
      position: absolute;
      right: inherit;
      left: 0.93333vw;
      top: 0.93333vw;
      width: 5.86667vw;
      height: 5.86667vw;
      background-color: #000;
      border-radius: 100%; }
      .tab-menu-type01 .round.card-type {
        top: 0.93333vw;
        right: 0.93333vw;
        left: inherit; }
  .tab-menu-type02 {
    margin-bottom: 9.33333vw; }
    .tab-menu-type02 button {
      width: 50%;
      padding-bottom: 4vw;
      border-bottom: 1px solid #ddd; }
      .tab-menu-type02 button .txt {
        font-family: "NotoSans-Medium";
        font-size: 4.26667vw;
        color: #999; }
      .tab-menu-type02 button + button {
        margin-left: -4px; }
      .tab-menu-type02 button.on {
        padding-bottom: 3.33333vw;
        border-bottom: 0.66667vw solid #000; }
        .tab-menu-type02 button.on .txt {
          color: #222; }
  /*! accordion */
  .ui-accordion {
    width: 100%;
    border-top: 2px solid #222; }
    .ui-accordion .accordion-item {
      border-bottom: 1px solid #ddd; }
      .ui-accordion .accordion-item .btn {
        position: relative;
        width: 100%;
        padding: 4.93333vw 2.66667vw;
        text-align: left;
        cursor: pointer; }
        .ui-accordion .accordion-item .btn .ico-q {
          display: inline-block;
          margin-right: 2.66667vw;
          vertical-align: middle;
          font-family: "Barlow";
          font-weight: 600;
          font-size: 4.8vw;
          line-height: 1;
          color: #00a551; }
        .ui-accordion .accordion-item .btn .txt {
          display: inline-block;
          width: 75%;
          word-break: keep-all;
          vertical-align: middle;
          font-family: "NotoSans-Medium";
          font-size: 4.26667vw;
          line-height: 1.4;
          color: #333; }
        .ui-accordion .accordion-item .btn .ico01, .ui-accordion .accordion-item .btn .ico02 {
          position: absolute;
          top: 50%;
          right: 2.66667vw;
          width: 4.13333vw;
          height: 0.4vw;
          background: #222;
          transition: .4s all; }
        .ui-accordion .accordion-item .btn .ico01 {
          transform: rotateZ(90deg); }
      .ui-accordion .accordion-item .detail {
        padding: 6.66667vw 5.33333vw;
        background-color: #fff; }
        .ui-accordion .accordion-item .detail p {
          white-space: pre-line;
          font-family: "NotoSans-DemiLight";
          font-size: 3.73333vw;
          line-height: 1.4;
          color: #666; }
        .ui-accordion .accordion-item .detail h2 {
          font-size: 5.6vw; }
        .ui-accordion .accordion-item .detail h3 {
          font-size: 4.26667vw; }
        .ui-accordion .accordion-item .detail h4 {
          font-size: 4vw; }
        .ui-accordion .accordion-item .detail .text-tiny {
          font-size: 2.53333vw; }
        .ui-accordion .accordion-item .detail .text-small {
          font-size: 3.06667vw; }
        .ui-accordion .accordion-item .detail .text-big {
          font-size: 5.2vw; }
        .ui-accordion .accordion-item .detail .text-huge {
          font-size: 6.66667vw; }
        .ui-accordion .accordion-item .detail strong {
          font-weight: 700; }
        .ui-accordion .accordion-item .detail blockquote {
          overflow: hidden;
          padding-right: 1.5em;
          padding-left: 1.5em;
          margin-left: 0;
          margin-right: 0;
          font-style: italic;
          border-left: 5px solid #ccc; }
        .ui-accordion .accordion-item .detail ol {
          list-style-type: decimal;
          padding-left: 21px;
          font-family: "NotoSans-DemiLight";
          font-size: 3.73333vw;
          line-height: 1.4;
          color: #666; }
        .ui-accordion .accordion-item .detail ul {
          list-style-type: circle;
          padding-left: 21px;
          font-family: "NotoSans-DemiLight";
          font-size: 3.73333vw;
          line-height: 1.4;
          color: #666; }
      .ui-accordion .accordion-item.on .ico01 {
        transform: rotateZ(0); } }

@media screen and (min-width: 750px) and (max-width: 1023px) {
  .tab-menu-type01 {
    width: 118px;
    height: 62px;
    padding: 7px;
    margin: 30px auto 0;
    border-radius: 31px; }
    .tab-menu-type01 .list {
      width: 18px;
      height: 18px; }
    .tab-menu-type01 .card {
      width: 22px;
      height: 17px; }
    .tab-menu-type01 .round {
      top: 7px;
      left: 7px;
      width: 44px;
      height: 44px; }
      .tab-menu-type01 .round.card-type {
        right: 7px;
        bottom: 7px;
        left: inherit;
        top: inherit; }
  .tab-menu-type02 {
    margin-bottom: 70px; }
    .tab-menu-type02 button {
      padding-bottom: 30px; }
      .tab-menu-type02 button .txt {
        font-size: 32px; }
      .tab-menu-type02 button.on {
        padding-bottom: 25px;
        border-bottom: 5px solid #000; }
  /*! accordion */
  .ui-accordion .accordion-item .btn {
    padding: 37px 20px; }
    .ui-accordion .accordion-item .btn .ico-q {
      margin-right: 20px;
      font-size: 36px; }
    .ui-accordion .accordion-item .btn .txt {
      font-size: 32px; }
    .ui-accordion .accordion-item .btn .ico01, .ui-accordion .accordion-item .btn .ico02 {
      right: 20px;
      width: 31px;
      height: 3px; }
  .ui-accordion .accordion-item .detail {
    padding: 50px 40px; }
    .ui-accordion .accordion-item .detail p {
      font-size: 28px;
      white-space: pre-line; }
    .ui-accordion .accordion-item .detail ol, .ui-accordion .accordion-item .detail ul {
      list-style: inherit;
      padding-left: 17px;
      font-family: "NotoSans-DemiLight";
      font-size: 28px;
      line-height: 1.4;
      color: #666; }
  .type-mo .paging-util {
    width: 66px;
    height: 66px;
    line-height: 66px; }
  .type-mo .paging-num {
    width: 66px;
    height: 66px;
    box-sizing: border-box;
    font-size: 32px; }
    .type-mo .paging-num .txt {
      font-size: 32px;
      line-height: 66px; }
    .type-mo .paging-num.on {
      border: 3px solid #222; }
    .type-mo .paging-num.on .txt {
      font-size: 32px;
      line-height: 60px; } }

@media screen and (max-width: 414px) {
  .tab-menu-type01 .list {
    vertical-align: top; }
  .tab-menu-type01 .card {
    vertical-align: top; } }

@media (max-width: 1023px) {
  [class*="web-"] {
    display: none; }
  #wrap {
    position: relative; }
  .inner {
    position: relative;
    width: 100%;
    padding: 0 5.33333vw;
    box-sizing: border-box; }
  /*! header */
  #header .header_content {
    display: flex;
    padding: 4vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #fff;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2); }
    #header .header_content .logo {
      width: 6.93333vw;
      height: 6.8vw;
      background: url(../../images/mo/logo.png) no-repeat;
      background-size: cover; }
    #header .header_content .txt {
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      text-align: center;
      align-self: center;
      font-family: "NotoSans-Bold";
      font-size: 4.8vw; }
    #header .header_content .menu {
      margin-left: auto;
      width: 5.86667vw;
      height: 3.86667vw; }
      #header .header_content .menu button {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        text-align: right;
        cursor: pointer;
        background: url(../../images/common/ico_menu.png) no-repeat;
        background-size: cover; }
        #header .header_content .menu button .hbar {
          display: none; }
  #nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 100;
    overflow-y: auto; }
  .nav-wrap {
    padding: 5.33333vw 5.33333vw 13.33333vw 5.33333vw;
    background-color: #ffffff;
    z-index: 20;
    box-sizing: border-box; }
    .nav-wrap .close {
      text-align: right;
      margin-bottom: 8vw; }
      .nav-wrap .close .btn-close {
        position: relative;
        width: 4.66667vw;
        height: 4.66667vw;
        padding: 0;
        margin: 0; }
        .nav-wrap .close .btn-close .hbar {
          position: absolute;
          right: 2vw;
          top: -0.8vw;
          display: block;
          width: 0.53333vw;
          height: 6.13333vw;
          background-color: #000;
          transform: rotate(45deg); }
          .nav-wrap .close .btn-close .hbar:nth-child(2) {
            transform: rotate(-45deg); }
    .nav-wrap .nav-cnt-box {
      padding: 5.33333vw 0vw;
      border-bottom: 1px solid #eee; }
      .nav-wrap .nav-cnt-box .nav-tit button {
        position: relative;
        display: block;
        width: 100%;
        text-align: left; }
        .nav-wrap .nav-cnt-box .nav-tit button.on .txt:after {
          width: 100%; }
        .nav-wrap .nav-cnt-box .nav-tit button .txt {
          display: inline-block;
          font-size: 5.6vw; }
          .nav-wrap .nav-cnt-box .nav-tit button .txt:lang(ko) {
            font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .nav-wrap .nav-cnt-box .nav-tit button .txt:lang(en) {
            font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .nav-wrap .nav-cnt-box .nav-tit button .txt:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 900; }
          .nav-wrap .nav-cnt-box .nav-tit button .txt:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 900; }
          .nav-wrap .nav-cnt-box .nav-tit button .txt:after {
            content: "";
            clear: both;
            display: block;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 0;
            height: 4px;
            background-color: #000;
            transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all; }
        .nav-wrap .nav-cnt-box .nav-tit button em {
          position: absolute;
          right: 0.8vw;
          top: 2.4vw;
          display: inline-block;
          width: 0.4vw;
          height: 2.66667vw;
          background-color: #999;
          transform: rotate(-45deg); }
          .nav-wrap .nav-cnt-box .nav-tit button em.ico01 {
            right: 2.4vw; }
          .nav-wrap .nav-cnt-box .nav-tit button em.ico02 {
            transform: rotate(45deg); }
        .nav-wrap .nav-cnt-box .nav-tit button.on em {
          transform: rotate(45deg); }
          .nav-wrap .nav-cnt-box .nav-tit button.on em.ico02 {
            transform: rotate(-45deg); }
      .nav-wrap .nav-cnt-box .nav-cnt {
        display: none;
        margin-top: 5.33333vw; }
        .nav-wrap .nav-cnt-box .nav-cnt.brand ul li {
          float: left;
          width: 41.33333vw;
          height: 24.53333vw;
          margin-right: 4vw;
          margin-bottom: 4vw;
          border-radius: 2.13333vw;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center; }
          .nav-wrap .nav-cnt-box .nav-cnt.brand ul li a {
            display: block;
            width: 100%;
            height: 100%; }
          .nav-wrap .nav-cnt-box .nav-cnt.brand ul li:nth-child(even) {
            margin-right: 0; }
        .nav-wrap .nav-cnt-box .nav-cnt.info a {
          display: inline-block;
          padding: 0vw 2.66667vw; }
          .nav-wrap .nav-cnt-box .nav-cnt.info a .txt {
            display: inline-block;
            font-family: "NotoSans-Medium";
            font-size: 4vw;
            line-height: 2.6;
            color: #666; }
        .nav-wrap .nav-cnt-box .nav-cnt.etc li {
          float: left;
          width: 41.33333vw;
          height: 26.53333vw;
          margin-right: 4vw;
          margin-bottom: 4vw;
          background-repeat: no-repeat;
          background-size: contain; }
          .nav-wrap .nav-cnt-box .nav-cnt.etc li.etc01 {
            background-image: url(../../images/main/etc_banner01.png); }
          .nav-wrap .nav-cnt-box .nav-cnt.etc li.etc02 {
            background-image: url(../../images/main/etc_banner02.png); }
          .nav-wrap .nav-cnt-box .nav-cnt.etc li.etc03 {
            background-image: url(../../images/main/etc_banner03.png); }
          .nav-wrap .nav-cnt-box .nav-cnt.etc li.etc04 {
            background-image: url(../../images/main/etc_banner04.png); }
          .nav-wrap .nav-cnt-box .nav-cnt.etc li:nth-child(even) {
            margin-right: 0; }
          .nav-wrap .nav-cnt-box .nav-cnt.etc li a {
            display: block;
            width: 100%;
            height: 100%; }
        .nav-wrap .nav-cnt-box .nav-cnt.on {
          display: block; }
  #container {
    margin-top: 24.53333vw;
    min-height: 76vh; }
  /*! sub-header */
  #header-sub {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 99; }
    #header-sub .header-content {
      border-bottom: 1px solid #e5e5e5; }
      #header-sub .header-content .header-wrap {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 20;
        background: #fff; }
        #header-sub .header-content .header-wrap .logo {
          position: absolute;
          top: 4vw;
          left: 4vw;
          z-index: 1; }
          #header-sub .header-content .header-wrap .logo a {
            display: block;
            width: 6.93333vw;
            height: 6.8vw;
            background: url(../../images/mo/logo.png) no-repeat;
            background-size: contain; }
        #header-sub .header-content .header-wrap .mo-brand-menu {
          width: 100%;
          padding: 3.6vw 0 1.6vw;
          align-self: center;
          text-align: center; }
          #header-sub .header-content .header-wrap .mo-brand-menu .current {
            display: inline-block;
            outline: none; }
            #header-sub .header-content .header-wrap .mo-brand-menu .current .txt {
              display: inline-block;
              font-size: 4.8vw;
              vertical-align: middle; }
              #header-sub .header-content .header-wrap .mo-brand-menu .current .txt:lang(ko) {
                font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              #header-sub .header-content .header-wrap .mo-brand-menu .current .txt:lang(en) {
                font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              #header-sub .header-content .header-wrap .mo-brand-menu .current .txt:lang(ja) {
                font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 900; }
              #header-sub .header-content .header-wrap .mo-brand-menu .current .txt:lang(zh) {
                font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 900; }
            #header-sub .header-content .header-wrap .mo-brand-menu .current .ico {
              display: inline-block;
              margin-left: 0.93333vw;
              width: 4.53333vw;
              height: 4.53333vw;
              vertical-align: middle;
              background: url(../../images/mo/ico_menu_arrow.png) no-repeat;
              background-size: contain; }
            #header-sub .header-content .header-wrap .mo-brand-menu .current.on .ico {
              transform: rotate(180deg); }
        #header-sub .header-content .header-wrap .util-menu {
          position: absolute;
          top: 4vw;
          right: 4vw;
          width: 5.86667vw;
          height: 3.86667vw; }
          #header-sub .header-content .header-wrap .util-menu .menu {
            display: block;
            width: 100%;
            height: 100%; }
            #header-sub .header-content .header-wrap .util-menu .menu button {
              display: block;
              width: 100%;
              height: 100%;
              padding: 0;
              text-align: right;
              cursor: pointer;
              background: url(../../images/common/ico_menu.png) no-repeat;
              background-size: cover; }
              #header-sub .header-content .header-wrap .util-menu .menu button .hbar {
                display: none; }
              #header-sub .header-content .header-wrap .util-menu .menu button:after {
                content: "";
                display: block;
                clear: both; }
      #header-sub .header-content .brand-list-wrap {
        display: none;
        overflow: auto;
        position: fixed;
        top: 15.2vw;
        left: 0;
        right: 0;
        height: calc(100% - 130px);
        z-index: 11; }
        #header-sub .header-content .brand-list-wrap .brand-list {
          background-color: #fff; }
          #header-sub .header-content .brand-list-wrap .brand-list li {
            padding: 0 8vw; }
            #header-sub .header-content .brand-list-wrap .brand-list li a {
              display: block;
              width: 100%;
              height: 100%;
              padding: 5.33333vw 0;
              text-align: center;
              border-bottom: 1px solid #eee; }
              #header-sub .header-content .brand-list-wrap .brand-list li a .txt {
                font-size: 4.26667vw; }
                #header-sub .header-content .brand-list-wrap .brand-list li a .txt:lang(ko) {
                  font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
                #header-sub .header-content .brand-list-wrap .brand-list li a .txt:lang(en) {
                  font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
                #header-sub .header-content .brand-list-wrap .brand-list li a .txt:lang(ja) {
                  font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
                  font-weight: 700; }
                #header-sub .header-content .brand-list-wrap .brand-list li a .txt:lang(zh) {
                  font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
                  font-weight: 700; }
            #header-sub .header-content .brand-list-wrap .brand-list li:hover, #header-sub .header-content .brand-list-wrap .brand-list li.on {
              background-color: #f1f5f8; }
            #header-sub .header-content .brand-list-wrap .brand-list li:last-child a {
              border-bottom: 0; }
      #header-sub .header-content .nav {
        width: 100%; }
        #header-sub .header-content .nav .swiper-wrapper {
          width: auto;
          min-width: 100%;
          display: flex;
          position: relative;
          background-color: #fff; }
          #header-sub .header-content .nav .swiper-wrapper .swiper-slide {
            flex-shrink: 0;
            width: 33.333333%;
            white-space: nowrap; }
            #header-sub .header-content .nav .swiper-wrapper .swiper-slide a {
              display: block;
              padding: 4vw 0vw 4.13333vw;
              text-align: center; }
              #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
                font-size: 3.73333vw;
                color: #999; }
                #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt:lang(ko) {
                  font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
                #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt:lang(en) {
                  font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
                #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt:lang(ja) {
                  font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
                  font-weight: 700; }
                #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt:lang(zh) {
                  font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
                  font-weight: 700; }
              #header-sub .header-content .nav .swiper-wrapper .swiper-slide a.on {
                padding: 4vw 0vw 3.46667vw;
                border-bottom: 0.66667vw solid #000; }
                #header-sub .header-content .nav .swiper-wrapper .swiper-slide a.on .txt {
                  color: #000; }
  .services #header-sub .header-content .header-wrap .mo-brand-menu, .pharmacy #header-sub .header-content .header-wrap .mo-brand-menu {
    padding: 3.6vw 0; }
  /*! sub-footer */
  #footer {
    background-color: #666; }
    #footer .footer-content {
      padding: 6.66667vw 0 5.33333vw; }
      #footer .footer-content .ft-logo-wrap a {
        display: inline-block;
        vertical-align: middle; }
        #footer .footer-content .ft-logo-wrap a:nth-child(1), #footer .footer-content .ft-logo-wrap a:nth-child(2) {
          width: 26.8vw;
          height: 4.93333vw;
          margin-right: 6.66667vw;
          background: url(../../images/mo/logo_ft_sub1.png) no-repeat;
          background-size: contain; }
          #footer .footer-content .ft-logo-wrap a:nth-child(1):lang(en), #footer .footer-content .ft-logo-wrap a:nth-child(1):lang(ja), #footer .footer-content .ft-logo-wrap a:nth-child(1):lang(zh), #footer .footer-content .ft-logo-wrap a:nth-child(2):lang(en), #footer .footer-content .ft-logo-wrap a:nth-child(2):lang(ja), #footer .footer-content .ft-logo-wrap a:nth-child(2):lang(zh) {
            width: 51.86667vw;
            background: url(../../images/common/logo_ft_sub1_en.png) no-repeat;
            background-size: contain; }
        #footer .footer-content .ft-logo-wrap a:last-child {
          width: 17.73333vw;
          height: 4.53333vw;
          background: url(../../images/mo/logo_ft_sub2.png) no-repeat;
          background-size: contain; }
        #footer .footer-content .ft-logo-wrap a.web-link {
          display: none; }
      #footer .footer-content p {
        font-size: 3.2vw;
        color: #fff;
        margin-top: 4.66667vw; }
        #footer .footer-content p:lang(ko) {
          font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        #footer .footer-content p:lang(en) {
          font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        #footer .footer-content p:lang(ja) {
          font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 300; }
        #footer .footer-content p:lang(zh) {
          font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 300; } }

@media screen and (max-width: 1023px) and (min-width: 750px) and (max-width: 1023px) {
  #container {
    margin-top: 184px; }
  #header .header_content {
    padding: 30px; }
    #header .header_content .logo {
      width: 52px;
      height: 51px; }
    #header .header_content .txt {
      font-size: 36px; }
    #header .header_content .menu {
      width: 44px;
      height: 29px; }
  .nav-wrap {
    padding: 40px 40px 100px 40px; }
    .nav-wrap .close {
      margin-bottom: 60px; }
      .nav-wrap .close .btn-close {
        width: 35px;
        height: 35px; }
        .nav-wrap .close .btn-close .hbar {
          right: 15px;
          top: -6px;
          width: 4px;
          height: 46px; }
    .nav-wrap .nav-cnt-box {
      padding: 40px 0px; }
      .nav-wrap .nav-cnt-box .nav-tit a .txt {
        font-size: 42px;
        border-bottom: 5px solid #222; }
      .nav-wrap .nav-cnt-box .nav-tit a em {
        right: 6px;
        top: 18px;
        width: 3px;
        height: 20px; }
        .nav-wrap .nav-cnt-box .nav-tit a em.ico01 {
          right: 18px; }
      .nav-wrap .nav-cnt-box .nav-cnt {
        margin-top: 40px; }
        .nav-wrap .nav-cnt-box .nav-cnt.info a {
          padding: 0 20px; }
          .nav-wrap .nav-cnt-box .nav-cnt.info a .txt {
            font-size: 30px; }
        .nav-wrap .nav-cnt-box .nav-cnt.etc a {
          padding: 0 20px; }
          .nav-wrap .nav-cnt-box .nav-cnt.etc a .txt {
            font-size: 30px; }
          .nav-wrap .nav-cnt-box .nav-cnt.etc a .ico {
            width: 27px;
            height: 26px;
            margin-left: 14px; }
  #header-sub .header-content .header-wrap .logo {
    top: 30px;
    left: 30px; }
    #header-sub .header-content .header-wrap .logo a {
      width: 52px;
      height: 51px; }
  #header-sub .header-content .header-wrap .mo-brand-menu {
    padding: 27px 0 12px; }
    #header-sub .header-content .header-wrap .mo-brand-menu .current .txt {
      font-size: 36px; }
    #header-sub .header-content .header-wrap .mo-brand-menu .current .ico {
      margin-left: 7px;
      width: 34px;
      height: 34px; }
  #header-sub .header-content .header-wrap .util-menu {
    position: absolute;
    top: 30px;
    right: 30px; }
    #header-sub .header-content .header-wrap .util-menu .menu {
      width: 44px;
      height: 29px; }
  #header-sub .header-content .brand-list-wrap {
    top: 114px; }
    #header-sub .header-content .brand-list-wrap .brand-list li {
      padding: 0 60px; }
      #header-sub .header-content .brand-list-wrap .brand-list li a {
        padding: 40px 0; }
        #header-sub .header-content .brand-list-wrap .brand-list li a .txt {
          font-size: 32px; }
  #header-sub .header-content .nav ul li a {
    padding: 30px 0px 31px; }
    #header-sub .header-content .nav ul li a .txt {
      font-size: 28px; }
    #header-sub .header-content .nav ul li a.on {
      padding: 30px 0px 26px;
      border-bottom: 5px solid #000; }
  #header-sub .header-content .nav .swiper-wrapper .swiper-slide a {
    padding: 30px 0px 31px; }
    #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
      font-size: 28px; }
    #header-sub .header-content .nav .swiper-wrapper .swiper-slide a.on {
      padding: 30px 0px 26px;
      border-bottom: 5px solid #000; }
  .services #header-sub .header-content .header-wrap .mo-brand-menu, .pharmacy #header-sub .header-content .header-wrap .mo-brand-menu {
    padding: 27px 0; }
  #footer .footer-content .ft-logo-wrap a:nth-child(1), #footer .footer-content .ft-logo-wrap a:nth-child(2) {
    width: 201px;
    height: 37px;
    margin-right: 50px; }
    #footer .footer-content .ft-logo-wrap a:nth-child(1):lang(en), #footer .footer-content .ft-logo-wrap a:nth-child(1):lang(ja), #footer .footer-content .ft-logo-wrap a:nth-child(1):lang(zh), #footer .footer-content .ft-logo-wrap a:nth-child(2):lang(en), #footer .footer-content .ft-logo-wrap a:nth-child(2):lang(ja), #footer .footer-content .ft-logo-wrap a:nth-child(2):lang(zh) {
      width: 389px; }
  #footer .footer-content .ft-logo-wrap a:nth-child(2) {
    width: 190px;
    height: 34px;
    background-size: cover; }
  #footer .footer-content .ft-logo-wrap a:last-child {
    width: 133px;
    height: 34px; }
  #footer .footer-content p {
    font-size: 24px;
    margin-top: 35px; } }

@media screen and (max-width: 1023px) and (-ms-high-contrast: active), (max-width: 1023px) and (-ms-high-contrast: none) {
  #container {
    margin-top: 22.93333vw; }
  .brand .title-wrap .title .sub-txt span {
    letter-spacing: -1px !important; }
  .brand .title-wrap .sub-txt span {
    letter-spacing: -1px !important; }
  .panpyrin .section01 .sub-tit {
    display: block; } }

@media screen and (max-width: 1023px) and (-ms-high-contrast: active) and (min-width: 750px) and (max-width: 1023px), screen and (max-width: 1023px) and (-ms-high-contrast: none) and (min-width: 750px) and (max-width: 1023px) {
  #container {
    margin-top: 172px; } }

@media (min-width: 1024px) {
  [class*="mo-"] {
    display: none !important; }
  #wrap {
    overflow: hidden; }
  .inner {
    position: relative;
    max-width: 1200px;
    margin: auto;
    z-index: 1; }
  .inner-1340 {
    position: relative;
    max-width: 1340px;
    margin: auto; }
  .inner-1480 {
    position: relative;
    max-width: 1480px;
    margin: auto; }
  /*! main-header */
  #header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10; }
    #header .header_content {
      display: flex;
      padding: 3.125vw; }
      #header .header_content .logo {
        width: 39px;
        height: 37px;
        background: url(../../images/common/logo.png) no-repeat; }
        #header .header_content .logo a {
          display: block;
          width: 100%;
          height: 100%; }
      #header .header_content .menu {
        margin-left: auto;
        width: 32px;
        height: 29px; }
        #header .header_content .menu button {
          display: block;
          width: 100%;
          height: 100%;
          padding: 0;
          text-align: right;
          cursor: pointer; }
          #header .header_content .menu button .hbar {
            float: right;
            display: block;
            width: 32px;
            height: 3px;
            margin-bottom: 8px;
            background-color: #463e3e; }
            #header .header_content .menu button .hbar:nth-child(1) {
              width: 26px; }
            #header .header_content .menu button .hbar:nth-child(3) {
              width: 20px; }
  #nav {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 110; }
  .nav-wrap {
    height: 100%;
    padding: 3.125vw 3.125vw 4.58333vw 5.26042vw;
    background-color: #ffffff;
    box-sizing: border-box; }
    .nav-wrap .close {
      text-align: right;
      margin-bottom: 10px; }
      .nav-wrap .close .btn-close {
        position: relative;
        width: 27px;
        height: 27px;
        padding: 0;
        margin: 0;
        cursor: pointer; }
        .nav-wrap .close .btn-close .hbar {
          position: absolute;
          right: 12px;
          top: -4px;
          display: block;
          width: 3px;
          height: 35px;
          background-color: #000;
          transform: rotate(45deg); }
          .nav-wrap .close .btn-close .hbar:nth-child(2) {
            transform: rotate(-45deg); }
    .nav-wrap .nav-cnt-wrap {
      overflow: hidden;
      height: 100%; }
    .nav-wrap .nav-list {
      float: left;
      margin-right: 54px; }
      .nav-wrap .nav-list li {
        margin-bottom: 38px; }
        .nav-wrap .nav-list li button {
          display: block; }
          .nav-wrap .nav-list li button:after {
            content: "";
            clear: both;
            display: block;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 0;
            height: 4px;
            background-color: #000;
            transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all; }
          .nav-wrap .nav-list li button .txt {
            display: inline-block;
            padding-bottom: 5px;
            transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1) all;
            font-family: "NotoSans-Bold";
            font-size: 36px;
            color: #ccc; }
          .nav-wrap .nav-list li button.on:after {
            width: 100%; }
          .nav-wrap .nav-list li button.on .txt {
            color: #000; }
          .nav-wrap .nav-list li button:hover {
            cursor: pointer; }
            .nav-wrap .nav-list li button:hover .txt {
              color: #000; }
    .nav-wrap .nav-cnt-box {
      overflow-y: auto;
      float: right;
      position: relative;
      height: 100%;
      padding: 0 46px; }
      .nav-wrap .nav-cnt-box .nav-cnt {
        display: none; }
        .nav-wrap .nav-cnt-box .nav-cnt.on {
          display: block; }
        .nav-wrap .nav-cnt-box .nav-cnt li {
          margin-bottom: 20px;
          transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all; }
          .nav-wrap .nav-cnt-box .nav-cnt li:hover {
            box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.35); }
          .nav-wrap .nav-cnt-box .nav-cnt li a {
            display: block;
            width: 100%;
            height: 100%; }
      .nav-wrap .nav-cnt-box .brand li {
        width: 254px;
        height: 146px;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all; }
      .nav-wrap .nav-cnt-box .info li {
        width: 254px;
        background-color: #f8f8f8;
        border-radius: 30px;
        transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all; }
        .nav-wrap .nav-cnt-box .info li a {
          display: block;
          width: 100%;
          padding: 20px 0;
          text-align: center; }
          .nav-wrap .nav-cnt-box .info li a .txt {
            transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all;
            font-family: "NotoSans-Medium";
            font-size: 20px;
            color: #000; }
        .nav-wrap .nav-cnt-box .info li:hover {
          background-color: #04a6d0; }
          .nav-wrap .nav-cnt-box .info li:hover .txt {
            color: #fff; }
      .nav-wrap .nav-cnt-box .etc li {
        width: 254px;
        height: 162px;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size: contain; }
        .nav-wrap .nav-cnt-box .etc li.etc01 {
          background-image: url(../../images/main/etc_banner01.png); }
        .nav-wrap .nav-cnt-box .etc li.etc02 {
          background-image: url(../../images/main/etc_banner02.png); }
        .nav-wrap .nav-cnt-box .etc li.etc03 {
          background-image: url(../../images/main/etc_banner03.png); }
        .nav-wrap .nav-cnt-box .etc li.etc04 {
          background-image: url(../../images/main/etc_banner04.png); }
  /*! sub-header */
  .brand-list-wrap {
    display: none; }
  #header-sub {
    position: fixed;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 99; }
    #header-sub .header-content {
      height: 90px;
      border-bottom: 1px solid #e5e5e5; }
      #header-sub .header-content .logo {
        position: absolute;
        top: 27px;
        left: 3.125vw;
        z-index: 10; }
        #header-sub .header-content .logo a {
          display: block;
          width: 39px;
          height: 37px;
          background: url(../../images/common/logo.png) no-repeat;
          background-size: cover; }
      #header-sub .header-content .nav .swiper-wrapper {
        display: flex;
        justify-content: center;
        align-content: center; }
        #header-sub .header-content .nav .swiper-wrapper .swiper-slide {
          width: auto;
          align-self: center; }
          #header-sub .header-content .nav .swiper-wrapper .swiper-slide a {
            display: block;
            margin: 0vw 2.08333vw;
            padding: 34px 0;
            text-align: center;
            box-sizing: border-box; }
            #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
              font-size: 20px;
              color: #999; }
              #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt:lang(ko) {
                font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt:lang(en) {
                font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt:lang(ja) {
                font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 700; }
              #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt:lang(zh) {
                font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 700; }
            #header-sub .header-content .nav .swiper-wrapper .swiper-slide a.on {
              padding: 34px 0 31px;
              border-bottom: 3px solid #000; }
              #header-sub .header-content .nav .swiper-wrapper .swiper-slide a.on .txt {
                color: #000; }
            #header-sub .header-content .nav .swiper-wrapper .swiper-slide a:hover {
              padding: 34px 0 31px;
              border-bottom: 3px solid #000; }
              #header-sub .header-content .nav .swiper-wrapper .swiper-slide a:hover .txt {
                color: #000; }
      #header-sub .header-content .util-menu {
        position: absolute;
        top: 31px;
        right: 3.125vw;
        z-index: 10; }
        #header-sub .header-content .util-menu .pharmacy {
          display: inline-block;
          margin-right: 4.16667vw;
          vertical-align: middle; }
          #header-sub .header-content .util-menu .pharmacy .txt {
            display: inline-block;
            font-size: 18px;
            vertical-align: middle; }
          #header-sub .header-content .util-menu .pharmacy .ico {
            display: inline-block;
            width: 18px;
            height: 24px;
            margin-right: 6px;
            vertical-align: middle;
            background-image: url(../../images/common/sprite_pc.png);
            background-position: 0px -167px;
            width: 18px;
            height: 24px;
            background-size: 219px 211px; }
        #header-sub .header-content .util-menu .menu {
          display: inline-block;
          width: 32px;
          height: 29px;
          vertical-align: middle; }
          #header-sub .header-content .util-menu .menu button {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0;
            text-align: right;
            cursor: pointer; }
            #header-sub .header-content .util-menu .menu button .hbar {
              float: right;
              display: block;
              width: 100%;
              height: 3px;
              margin-bottom: 8px;
              background-color: #463e3e; }
              #header-sub .header-content .util-menu .menu button .hbar:nth-child(1) {
                width: 26px; }
              #header-sub .header-content .util-menu .menu button .hbar:nth-child(3) {
                width: 20px; }
  /*! sub-footer */
  #footer {
    background-color: #666; }
    #footer .footer-content {
      padding: 34px 0 33px; }
      #footer .footer-content .inner {
        display: flex; }
        #footer .footer-content .inner .ft-logo-wrap a {
          display: inline-block;
          vertical-align: middle; }
          #footer .footer-content .inner .ft-logo-wrap a:nth-child(1), #footer .footer-content .inner .ft-logo-wrap a:nth-child(2) {
            width: 100px;
            height: 18px;
            margin-right: 20px;
            background: url(../../images/common/logo_ft_sub1.png) no-repeat;
            background-size: cover; }
            #footer .footer-content .inner .ft-logo-wrap a:nth-child(1):lang(en), #footer .footer-content .inner .ft-logo-wrap a:nth-child(1):lang(ja), #footer .footer-content .inner .ft-logo-wrap a:nth-child(1):lang(zh), #footer .footer-content .inner .ft-logo-wrap a:nth-child(2):lang(en), #footer .footer-content .inner .ft-logo-wrap a:nth-child(2):lang(ja), #footer .footer-content .inner .ft-logo-wrap a:nth-child(2):lang(zh) {
              width: 188px;
              background: url(../../images/common/logo_ft_sub1_en.png) no-repeat; }
          #footer .footer-content .inner .ft-logo-wrap a:last-child {
            width: 61px;
            height: 16px;
            background: url(../../images/common/logo_ft_sub2.png) no-repeat;
            background-size: contain; }
        #footer .footer-content .inner p {
          font-family: "NotoSans-Light";
          font-size: 14px;
          color: #fff;
          margin-left: auto; }
  /*! popup */
  /*! layout */
  #wrap {
    position: relative; }
  .sub #container {
    padding-top: 90px; } }

@media screen and (min-width: 1024px) and (min-width: 1024px) and (max-width: 1200px) {
  .inner {
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box; } }
